import { axiosInstance } from "../../../../axiosInstance";
import { IAuth, IUser, IPortalClient, IStatus } from "../core/_models";
import useAxiosPrivate from "./useAxiosPrivate"

const useAuthRequests = () => {
  const axisosPrivate = useAxiosPrivate();

  async function getPrograms() {
    const {data} = await axiosInstance.get<IPortalClient[]>(`/get_programs`)
    return data
  }
  async function getStatus() {
    const {data} = await axisosPrivate.get<{data: IStatus[]}>(`/get_all_status`)
    return data
  }

  async function axiosAuthLogout(data: null) {
    const response = await axisosPrivate.get('/logout')
    return response
  }
  async function getCurrentUser() {
    const {data} = await axisosPrivate.get<{message: string; currentUser: IUser}>('/authenticate')
    return data
  }

  async function redirectProgram(clientData: {client:string}) {
    const {data} = await axisosPrivate.post('/auth/redirect_program', clientData)
    return data
  }

  async function processRedirectProgram({client, token}: {client: string, token: string}) {
    return await axiosInstance.post<IAuth>(
      '/auth/process_switch_program',
      {client},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
  }

  return {
    getPrograms,
    axiosAuthLogout,
    getCurrentUser,
    redirectProgram,
    processRedirectProgram,
    getStatus,
  }
}

export default useAuthRequests