import {PSListToolbar} from './PSListToolbar'
import {PSListSearchComponent} from './PSListSearchComponent'

const PSListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <PSListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {<PSListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {PSListHeader}
