
import {FC, useState, createContext, useContext, useMemo} from 'react'
import {
  ID,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  initialListView,
  ListViewContextProps,
} from '../../../../_metronic/helpers'
import { useQueryResponse, useQueryResponseData } from '../../apps/user-management/users-list/core/QueryResponseProvider'

type ListViewProviderProps = {
  children: JSX.Element
  initialState: ListViewContextProps
};

const ListViewContext = createContext<ListViewContextProps>(initialListView)

const ListViewProvider: FC<ListViewProviderProps> = ({ children, initialState }) => {
  const [selected, setSelected] = useState<Array<ID>>(initialState.selected)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListView.itemIdForUpdate)
  const [itemIdForDelete, setItemIdForDelete] = useState<{id: ID, email: string} | undefined>(initialListView.itemIdForDelete)
  const { isLoading } = useQueryResponse()
  const data = useQueryResponseData()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])

  const value = useMemo(() => ({
    selected,
    itemIdForUpdate,
    setItemIdForUpdate,
    itemIdForDelete,
    setItemIdForDelete,
    disabled,
    isAllSelected,
    onSelect: (id: ID) => {
      groupingOnSelect(id, selected, setSelected)
    },
    onSelectAll: () => {
      groupingOnSelectAll(isAllSelected, setSelected, data)
    },
    clearSelected: () => { 
      setSelected([])
    },
    }), [selected, itemIdForUpdate, itemIdForDelete, disabled, isAllSelected, data])


  return (
    <ListViewContext.Provider value={value}>
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}