import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { IoCloseSharp } from "react-icons/io5";
import { axiosPrivate } from '../../../../../axiosInstance'
import moment from 'moment-timezone'
import { currencyFormat } from '../../../../../utils/funcHelpers';

interface IProps {
  opportunity: number
  notificationHandler: any
  refetch: any
}

interface Plan {
  date: string,
  amount: string,
  isPaid: boolean,
  isRemoved?: boolean,
  originalAmount?: string
}

const EditPlan = ({opportunity, notificationHandler, refetch}: IProps) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [newPlan, setNewPlan] = useState<Plan[]>([])
  const [paymentPlanSchedule, setPaymentSchedule] = useState<any>()
  const fetchOpportunity = async () => {
    try {
      // setLoading(true)
      const res = await axiosPrivate.post('/fetch_opportunity_payment_plan', {opportunity})
      const parsePaymentPlan = res.data.payment_schedule_plan.dueDates
      setPaymentSchedule(res.data.payment_schedule_plan)
      if (parsePaymentPlan.length) {
        setNewPlan(parsePaymentPlan)
      }
    } catch (error: any) {
      setErrorMessage(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (showModal) {
      fetchOpportunity()
    }
  }, [showModal])

  const handleClose = () => {
    setShowModal(prev => !prev)
    notificationHandler('','dismiss')
    setLoading(false)
    setErrorMessage('')
    setSuccessMessage('')
    setNewPlan([])
    refetch()
  }


  
  const onSubmit = async () => {
    setLoading(true)
    setErrorMessage('')
    setSuccessMessage('')
    try {
      const checkInputs  = newPlan.filter(item => Number(item?.amount) === 0 && !item.isPaid)
      if (checkInputs.length > 0) {
        notificationHandler('Please input an amount before updating', "warn")
      } else {
        const totalAmount = newPlan.reduce((accumulator, currentItem) => {
          return accumulator + parseFloat(currentItem.amount);
        }, 0)
        const isEqualPrice = Number(totalAmount).toFixed(2) === Number(paymentPlanSchedule.currentBalance).toFixed(2)
        const dateToday = moment(new Date())
        if (!isEqualPrice) {
          const isEqualPriceMsg = !isEqualPrice ? (<li>Total sum of Payments do not equal Total Remaining</li>) : ''
          const msg = (
              <div>
                <p>Cannot Update please fix the following:</p>
                <ul>
                  {isEqualPriceMsg}
                </ul>
              </div>
          )
          notificationHandler(
            msg,
            'error'
          )
        } else {
          const res = await axiosPrivate.post('update_payment_plan', {opportunity, newPlan})
          setSuccessMessage(res.data)
          setTimeout(() => {
            handleClose()
          }, 1000)
        }
      }
    } catch (error: any) {
      setErrorMessage(error.message)     
    } finally {
      setLoading(false)
    }
  }

  // const handleOnChange = (index: number, value: any) => {
  //   setNewPlan(prev => prev.map((item, idx: number) => {
  //     if (idx === index) {
  //       return {
  //         ...item,
  //         amount: value || ''
  //       }
  //     } else {
  //       return item
  //     }
  //   }))
  // }

  // const checkHandleOnChange = (index: number, value: any) => {
  //   setNewPlan(prev => prev.map((item, idx: number) => {
  //     if (idx === index) {
  //       return {
  //         ...item,
  //         isRemoved: value || false
  //       }
  //     } else {
  //       return item
  //     }
  //   }))
  // }

  // const [newInputIndices, setNewInputIndices] = useState([0]); // Indices of newly added inputs

  // const handleDateChange = (index: any, date: any) => {
  //   const updatedPlan = [...newPlan];
  //   updatedPlan[index].date = date;
  //   setNewPlan(updatedPlan);
  // };

  // const handleAmountChange = (index: any, value: any) => {
  //   const updatedPlan = [...newPlan];
  //   updatedPlan[index].amount = value;
  //   setNewPlan(updatedPlan);
  // };

  // const handleCheckboxChange = (index: any, isChecked: any) => {
  //   const updatedPlan = [...newPlan];
  //   updatedPlan[index].isRemoved = isChecked;
  //   setNewPlan(updatedPlan);
  // };

  // const AddInputHandler = () => {
  //   const newIndex = newPlan.length; // Index of the newly added input
  //   setNewPlan(prevPlan => [
  //     ...prevPlan,
  //     { date: '', amount: '', isRemoved: false, isPaid: false }
  //   ]);
  //   setNewInputIndices(prevIndices => [...prevIndices, newIndex]);
  // };

  const handleDateChange = (index: any, date: any) => {
    const formatDate = moment(date).tz(moment.tz.guess()).format('MM/DD/YYYY');
    const updatedPlan = [...newPlan];
    updatedPlan[index].date = formatDate;
    setNewPlan(updatedPlan);
  };

  const handleAmountChange = (index: any, value: any) => {
    
    const parseData = value ? value.replace(/^0+/, '') : '0'
    const updatedPlan = [...newPlan];
    updatedPlan[index].amount = parseData.toString();
    updatedPlan[index].originalAmount = parseData.toString();
    setNewPlan(updatedPlan);
  };

  const onFocusAmountChange = (index: any, input: any) => {
    // Remove non-numeric characters
    let value = input.replace(/[^\d.]/g, '');

    // If there's more than one decimal point, keep only the first one
    const decimalIndex = value.indexOf('.');
    if (decimalIndex !== -1) {
      value = value.slice(0, decimalIndex + 3);
    }

    // If the value ends with a decimal point, add a zero after it
    if (value.endsWith('.')) {
      value += '0';
    }

    // If the value starts with a decimal point, prepend a zero
    if (value.startsWith('.')) {
      value = '0' + value;
    }

    // If the value doesn't have a decimal point, add .00 to represent cents
    if (!value.includes('.')) {
      value += '.00';
    }

    // Making Sure that decimal Number has 2 place, making 1.3 to 1.30
    const parts = value.split('.');
    if (parts[1] && parts[1].length === 1) {
      value += 0;
    }

    // Ensure that if the number is in the format 0.x, it becomes 0.xx
    if (value.startsWith('0.') && value.length === 3) {
      value += '0';
    }

    const updatedPlan = [...newPlan];
    updatedPlan[index].amount = value.toString();
    updatedPlan[index].originalAmount = value.toString();
    setNewPlan(updatedPlan);
}

  
  const handleRemoveDate = (index: any) => {
    const removePlan = newPlan.filter((item, i) => i === index);
    const removeDate = (index: any) => {
      const updatedPlan = newPlan.filter((item, i) => i !== index);
      setNewPlan(updatedPlan);
      notificationHandler(
        'Successfully Removed!',
        'success'
      )
    }
    const cancelRemove = () => {
      notificationHandler(
        '',
        'dismiss'
      )
    }
    const msg = (
      <div className='d-flex flex-column'>
        <p>Are you sure you want to remove this {removePlan[0].date} amount of ${removePlan[0].amount}?</p>
        <div className='d-flex justify-content-center'>
          <button
            className='btn btn-danger me-7'
            onClick={() => removeDate(index)}
          >
            Yes
          </button>
          <button
            className='btn btn-success me-7'
            onClick={() => cancelRemove()}
          >
            No
          </button>
        </div>
      </div>
    );
    notificationHandler(
      msg,
      'warn',
      {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      }
    )
  };


  const AddInputHandler = () => {
    if(newPlan) {
      const checkInputs  = newPlan.filter(item => Number(item?.amount) === 0 && !item.isPaid)
      if (checkInputs.length > 0) {
        notificationHandler('Please input an amount before adding a new Date', "warn")
      } else {
        const todayData = moment().tz(moment.tz.guess()).format('MM/DD/YYYY');
        setNewPlan(prevPlan => [
          ...prevPlan,
          { date: todayData , amount: '0', isRemoved: false, isPaid: false, originalAmount: '0' }
        ]);
      }
    }
  };
  const getTotalAmount = () => {
    const totalAmount = newPlan.reduce((accumulator, currentItem) => {
        const currentAmount = currentItem.amount ? currentItem.amount : '0'
        return accumulator + parseFloat(currentAmount);
    }, 0); // Start with an initial value of 0 for the accumulator
    return totalAmount;
  };
  const getTotalAmountMissing = () => {
    const totalAmount = newPlan.reduce((accumulator, currentItem) => {
        const currentAmount = currentItem.amount ? currentItem.amount : '0'
        return accumulator + parseFloat(currentAmount);
    }, 0); // Start with an initial value of 0 for the accumulator

    return (paymentPlanSchedule.currentBalance - totalAmount).toFixed(2);
  };
  return (
    <>
      <span className='text-primary' style={{cursor: 'pointer'}} onClick={handleClose}> - Edit</span>
      <Modal show={showModal} onHide={handleClose} backdrop='static' keyboard={false} className='modal-lg' centered>
        <Modal.Body className='p-0'>
          <Modal.Header closeButton>
            <Modal.Title>Payment Plan</Modal.Title>
          </Modal.Header>
          {paymentPlanSchedule ? (<h2 className='m-7'>Total Remaining: {currencyFormat.format(Number(paymentPlanSchedule.currentBalance))}</h2>) : ''}
          {newPlan.map((item, index) => {
            const formatDate = moment(item.date).tz(moment.tz.guess()).format('YYYY-MM-DD');
            return(
              <div key={index}
                className='d-flex fv-row my-7 px-7 justify-content-between'
              >
                <input
                  type="date"
                  value={formatDate}
                  className='form-control me-7'
                  onChange={(e) => handleDateChange(index, e.target.value)} />
                <input 
                  type="number"
                  value={item.amount}
                  onBlur={(e) => onFocusAmountChange(index, e.target.value)}
                  className='form-control me-7' 
                  onChange={(e) => handleAmountChange(index, e.target.value)} />
                <button
                  className='btn btn-danger me-7'
                  onClick={(e) => handleRemoveDate(index)}
                  >
                  <IoCloseSharp />
                </button>
                <span className='text-success mx-2'>{item.isPaid ? 'Paid' : ''}</span>
              </div>
            )}
          )}
      <div 
        onClick={AddInputHandler}
        className='btn btn-outline-primary btn-active-light-primary btn-sm'>
          + Add new input
      </div>

          {errorMessage ? (
            <div className='card-body'>
              <div className='alert alert-danger d-flex align-items-center px-5 py-3 mx-7'>
                <div className='d-flex flex-column '>
                  <span>{errorMessage}</span>
                </div>
              </div>
            </div>
          ) : ''}

          {successMessage ? (
            <div className='card-body'>
              <div className='alert alert-success d-flex align-items-center px-5 py-3 mx-7'>
                <div className='d-flex flex-column '>
                  <span>{successMessage}</span>
                </div>
              </div>
            </div>
          ) : ''}

        </Modal.Body>
          <Modal.Footer style={{padding: '20px 30px'}}>
            {paymentPlanSchedule ? (
              <>
                <h2 className='me-auto'>Total: {currencyFormat.format(getTotalAmount()) } ({getTotalAmountMissing()})</h2>
              </>
              ) : ''}
            <Button variant='secondary' onClick={handleClose}>
              Discard
            </Button>
            <Button 
              variant='primary' 
              className='ms-2' 
              onClick={onSubmit}
            >
              {loading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : 'Update'}
            </Button>
          </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditPlan