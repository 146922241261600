import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate, generatePath} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'

import {useAuth} from '../modules/auth'
import TerraRoute from './TerraRoute'

const PrivateRoutes = () => {
  const {currentUser, currentLocalDomain} = useAuth()
  console.log('currentUser', currentUser)

  const RoutePages = () => {
    if (currentLocalDomain === 'portal.terraed.com' && currentUser?.currentClient)
      return <Route path='/*' element={<TerraRoute />} />
    else return ''
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route
          path='auth/*'
          element={
            <Navigate to={`/dashboard/${currentUser?.currentClient}`} />
          }
        />
        {/* Pages */}
        {RoutePages()}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}

