import {useMutation, useQuery} from 'react-query'
import {Formik, Form, FormikValues} from 'formik'
import {getPartTwoObject} from '../../../../utils/funcHelpers'
import _queryKeys from '../../../modules/application/core/_queryKeys'
import {
  initPolicies,
  IPredepartureItem,
  partTwoSchemas,
} from '../../../modules/application/core/_models'
import useAppRequests from '../../../modules/application/Hooks/useAppRequests'
import Step4 from '../../../modules/application/components/PartTwoSteps/Step4'
import {useAuth} from '../../../modules/auth'
import {useParams} from 'react-router-dom'
interface Props {
  predeparture: IPredepartureItem,
  opportunity: string,
}

const MedicalInfo = ({predeparture}: Props) => {
  const {currentUser} = useAuth()
  const {opportunity} = useParams()
  const {fetchEnrollPartTwo, saveAndContinueLater} = useAppRequests()
  const {client} = useParams()
  const clientRoutes = client === '47821104' ? 'SSB' : client === '88562775' ? 'TTN' : 'GLA'
  const {
    isLoading: isFieldLoading,
    isError: isFieldError,
    data: fieldFormsData,
    refetch: refetchfieldFormsData,
  } = useQuery(_queryKeys.getEnrollPartTwoForms, () => fetchEnrollPartTwo(opportunity))

  getPartTwoObject(fieldFormsData?.data, 'medical_form')?.forEach((val) => {
    initPolicies.customField[val.name] = val.answer
      ? val.field_type === 'checkbox'
        ? val.answer.split(',')
        : val.answer
      : ''
  })

  const {
    isLoading: isSaveLoading,
    isSuccess: isSaveSuccess,
    isError: isSaveResponseError,
    mutate: saveResponse,
    mutateAsync: saveResposeAsync,
  } = useMutation(saveAndContinueLater)

  if (isFieldLoading) {
    return (
      <div className='w-100 my-10'>
        <div className='d-flex justify-content-center align-items-center'>
          <span className='me-5'>Please wait...</span>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      </div>
    )
  }

  const onSave = async (values: any, actions: FormikValues) => {
    console.log('onsave', values)

    const inputs: any = {
      opportunity: currentUser?.currentOpportunity,
      medical_form: {
        ...values.medical_form,
        predeparture_id: predeparture.id,
      },
    }
    saveResponse(inputs, {
      onSuccess: (res) => {
        if (res.data) {
          refetchfieldFormsData()
        }
      },
      onError: (err) => {
        console.log(err)
      },
    })
  }

  if (isFieldError) {
    return (
      <div className='alert alert-danger d-flex align-items-center p-5 my-10'>
        <div className='d-flex flex-column'>
          <h5 className='mb-1'>Oops!</h5>
          <span>Something went wrong!</span>
        </div>
      </div>
    )
  }

  return (
    <>
      {isSaveSuccess && (
        <div className='alert alert-success d-flex align-items-center p-5 mb-10'>
          <div className='d-flex flex-column '>
            <span>Your information has been saved and submitted to {clientRoutes} for review.</span>
          </div>
        </div>
      )}
      {isSaveResponseError && (
        <div className='alert alert-danger d-flex align-items-center p-5 my-10'>
          <div className='d-flex flex-column'>
            <h5 className='mb-1'>Oops!</h5>
            <span>Could not process your forms!</span>
          </div>
        </div>
      )}
      {isSaveLoading && (
        <div id='splash-screen2' className='splash-screen2'>
          <div className='border rounded bg-white py-5 px-15'>
            <span className='fs-3 fw-bold'>Saving ...</span>
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </div>
        </div>
      )}
      <div className='card mb-5 mb-xl-8'>
        <div className='card-body'>
          <div className='card-header px-0'>
            <h1>Medical Information</h1>
          </div>
          <Formik
            validationSchema={partTwoSchemas[3]}
            initialValues={initPolicies}
            onSubmit={onSave}
          >
            {({values, errors, setFieldValue}) => {
              console.log('errors', errors)

              return (
                <Form>
                  <Step4 currentFormikVal={values} />
                  <div className='text-end'>
                    <button type='submit' className='btn btn-lg btn-primary me-3'>
                      {!isFieldLoading && (
                        <span className='indicator-label'>
                          Save
                          <i className='fa-regular fa-floppy-disk ms-2'></i>
                        </span>
                      )}
                      {isFieldLoading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default MedicalInfo
