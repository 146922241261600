import * as Yup from 'yup'
import { IBaseField } from '../../application/core/_models'
import {ListViewContextProps} from '../../../../_metronic/helpers'
export const emailYup = Yup.string()
  .email('Wrong email format')
  .min(3, 'Minimum 2 symbols')
  .max(50, 'Maximum 50 symbols')


export const baseTextYup = Yup.string()
  .min(2, 'Minimum 2 symbols')
  .max(50, 'Maximum 50 symbols')

  export const phoneRegExp =
  // /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    /\(?\+?\d*\)??\s*\d+\s*\d*$/;

export interface ITab {
  title: string
  group: string
  form_data: IBaseField[]
  table_data?: {[key: string]: any}[]
  choicesObject?: {value: string, title: string}[]
}

export const initialListView: ListViewContextProps = {
  selected: [],
  itemIdForUpdate: undefined,
  itemIdForDelete: undefined,
  setItemIdForUpdate: () => {},
  setItemIdForDelete: () => {},
  isAllSelected: false,
  disabled: false,
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
};

export const initialCampaignListView: ListViewContextProps = {
  selected: [],
  itemIdForUpdate: undefined,
  itemIdForDelete: undefined,
  setItemIdForUpdate: () => {},
  setItemIdForDelete: () => {},
  isAllSelected: false,
  disabled: false,
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
};
