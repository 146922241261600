import React from 'react'
import {axiosInstance, axiosPrivate} from '../../../../axiosInstance'
import {
  IFlight,
  IFlightData,
  IPortalClient,
  IPaymentMethods,
  IPaymentMethod,
  ICurrentStepObject,
  IEnrollmentStatus,
  IEnrollPartTwoForm,
  IEnrollmentSummary,
  IPayOpt,
  IPredepartureInfo,
  IProgram,
  IProduct,
  IDocumentFile,
  IEnrollment,
  // ITuition,
  IGeneralFee,
} from '../core/_models'

const useAppRequests = () => {

  const RequestPresignedDocument = async (filekey: string) => {
    try {
      const response = await axiosPrivate.post(`/request/predeparture_file`, {filekey})
      return response
    } catch (error) {
      console.error(error)
    }
  }

  async function getListOfEnrollees(guardianID: number | string) {
    if (!guardianID) {
      throw new Error('Kindly provide a Guardian ID')
    }

    try {

      const { data } = await axiosPrivate.get(`/auth/get_enrollees_by_guardian`)
      return data;

    } catch (error) {
      console.error("- - - Error (useAppRequests.getListOfEnrollees) - - -")
      console.error(error)

      throw new Error('Failed to fetch list of enrollees under the same guardian')
    }
  }

  async function getProgramsEnroll(clientId: string | undefined) {
    if (!clientId) {
      throw new Error('Invalid clientId')
    }
  
    try {
      const { data } = await axiosInstance.get<{ programs: IProduct[]; general_fees: IGeneralFee[] }>(
        `/enroll/${clientId}`
      );
      return data;
    } catch (error) {
      console.error('Error while fetching programs and general fees:', error);
      throw new Error('Failed to fetch programs and general fees');
    }
  }

  async function getListOfFlightsByUser() {
    const {data} = await axiosPrivate.get<IFlight[]>(`/get_flights_by_user`)
    return data
  }

  async function getCurrentClientDetails() {
    const {data} = await axiosPrivate.get<IPortalClient>(`/get_client`)
    return data
  }

  async function getFlightRostersById(id: number) {
    const {data} = await axiosPrivate.get<{data: IFlightData}>(`/flight/${id}`)
    return data
  }

  async function setupProgramsEnroll(input: IProgram) {
    const {data} = await axiosInstance.post('/enroll', input)
    return data
  }

  async function setupProgramsEnrollAuthReq(input: IProgram) {
    const {data} = await axiosPrivate.post('/enroll_auth', input)
    return data
  }

  async function setupProgramsEnrollPayment(input: IPayOpt) {
    const {data} = await axiosPrivate.post('/confirm_and_pay', input)
    return data
  }

  async function saveAndContinueLater(input: ICurrentStepObject | null) {
    const {data} = await axiosPrivate.post('/save_continue_later', input)
    return data
  }

  async function fetchEnrollPartTwo(opportunity?: string) {
    const {data} = await axiosPrivate.get<{data: IEnrollPartTwoForm[]; status: number}>(
      `/fetch_enroll_part_two/${opportunity}`
    )
    return data
  }

  async function fetchPredetureInfoFormsReq(category: string, name: string, opportunity: number) {
    console.log("opportunity", opportunity)
    const {data} = await axiosPrivate.get<IPredepartureInfo>(`/fetch_predeparture_info/${category}/${name}/${opportunity}`)
    return data
  }
  async function enrollPartTwoFinalSubmission(input: {opportunity: number | null}) {
    const {data} = await axiosPrivate.post('/enroll_part_two_final_submission', input)
    return data
  }

  async function getRecentPaymentSummariesReq() {
    const {data} = await axiosPrivate.get<IEnrollmentSummary>('/fetch_most_recent_opp_payment')
    return data
  }
  async function getAllEnrollmentSummariesReq() {
    try {
      const {data} = await axiosPrivate.get<IEnrollmentSummary[]>('/fetch_all_opp_payments')
      return data
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
      }
      throw error
    }
  }

  async function getOpportunityReq(clientId?: string, opportunityId?: number) {
    const {data} = await axiosPrivate.get<IEnrollment>(
      `/fetch_opportunity/${clientId}/${opportunityId}`
    )
    return data
  }

  async function setOpportunityActiveReq(opportunity: number | undefined) {
    const {data} = await axiosPrivate.get(`/set_opportunity_active/${opportunity}`)
    return data
  }
  async function getPaymentSummaryReq(clientId: string, opportunityId: number) {
    const {data} = await axiosPrivate.get<IEnrollmentSummary>(
      `/fetch_opp_payment/${clientId}/${opportunityId}`
    )
    return data
  }

  async function fetchEnrollStatusReq() {
    const {data} = await axiosPrivate.get<IEnrollmentStatus>(`/fetch_enroll_status`)
    return data
  }

  async function getUserPaymentsReq(user: number | undefined | null) {
    const {data} = await axiosPrivate.get<IPaymentMethods>(`/get_payments/${user}`)
    return data
  }
  async function makeAPaymentReq(input: {
    payment_amount: number
    opportunity: number | undefined | null
  }) {
    const {data} = await axiosPrivate.post('/make_a_payment', input)
    return data
  }

  async function addPaymentMethodReq(pMethod: IPaymentMethod) {
    const {data} = await axiosPrivate.post<IPaymentMethods>(`/add_payment_method`, pMethod)
    return data
  }
  async function updatePaymentMethodReq(pMethod: IPaymentMethod) {
    const {data} = await axiosPrivate.post<IPaymentMethods>(`/update_payment_method`, pMethod)
    return data
  }
  async function deletePaymentMethodReq(inputs: any) {
    const {data} = await axiosPrivate.post(`/delete_payment_method`, inputs)
    return data
  }
  async function setActivePaymentMethodReq(input: {id: number}) {
    const {data} = await axiosPrivate.post<IPaymentMethods>(`/set_active`, input)
    return data
  }

  async function fetchAllProductsByAdminReq(clientID: any) {
    const { data } = await axiosPrivate.get(`/terra-admin/fetch_all_products/${clientID}`)
    return data;
  }

  async function updatePredepartureStatusReq(inputs: any) {
    const {data} = await axiosPrivate.post(`/update_predeparture_status`, inputs);
    return data;
  }

  async function updatePredepartureTaggingReq (inputs: any) {
    const { data } = await axiosPrivate.post(`/update_predeparture_tagging`, inputs);
    return data;
  }
  
  async function getPredepartureDocumentsReq({
    group,
    opportunity_id,
  }: {
    group: string | undefined
    opportunity_id: number | undefined | null
  }) {
    if (!group || !opportunity_id) {
      return null
    }
    const {data} = await axiosPrivate.get<{data: IDocumentFile[]}>(
      `/get_predeparture_documents/${group}/${opportunity_id}`
    )
    return data
  }

  async function fetchHelloSignRequestByIDReq (opportunityID: number | string | undefined, predepartureID: number | string | undefined) {
    const { data } = await axiosPrivate.get(`/hellosign/fetchHelloSignRequestByID/${opportunityID}/${predepartureID}`)
    return data;
  }

  return {
    getListOfEnrollees,
    getProgramsEnroll,
    setupProgramsEnroll,
    setupProgramsEnrollPayment,
    saveAndContinueLater,
    fetchEnrollPartTwo,
    enrollPartTwoFinalSubmission,
    getRecentPaymentSummariesReq,
    setupProgramsEnrollAuthReq,
    fetchEnrollStatusReq,
    getAllEnrollmentSummariesReq,
    setOpportunityActiveReq,
    getUserPaymentsReq,
    makeAPaymentReq,
    fetchPredetureInfoFormsReq,
    addPaymentMethodReq,
    updatePaymentMethodReq,
    deletePaymentMethodReq,
    setActivePaymentMethodReq,
    getListOfFlightsByUser,
    getFlightRostersById,
    getCurrentClientDetails,
    updatePredepartureTaggingReq,
    updatePredepartureStatusReq,
    getPredepartureDocumentsReq,
    getPaymentSummaryReq,
    getOpportunityReq,
    fetchAllProductsByAdminReq,
    fetchHelloSignRequestByIDReq,
    RequestPresignedDocument
  }
}

export default useAppRequests
