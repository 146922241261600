import { Link, useParams } from 'react-router-dom'
import { IPredepartureItem } from '../../core/_models'
import {PREV_AUTH_LOCAL_STORAGE_KEY, getAuthPromise, useAuth} from '../../../auth'
import { useEffect, useState } from 'react'
interface Props {
  predeparture: IPredepartureItem[] | null
  opportunity: number
}

const Predeparture = ({predeparture, opportunity}: Props) => {
  const {currentUser} = useAuth()
  const {client} = useParams()
  const [hasAdmin, setHasAdmin] = useState(false);

  const clientRoutes = client === '47821104' ? 'SSB' : client === '88562775' ? 'TTN' : 'GLA'
  const clientEmail =
    client === '47821104'
      ? 'support@summerspringboard.com'
      : client === '88562775'
      ? 'travel@teentravelnetwork'
      : 'support@experiencegla.com'

  useEffect(() => {
    if (predeparture) {
      const checkAdmin = async () => {
        const prevAuth = await getAuthPromise(PREV_AUTH_LOCAL_STORAGE_KEY)
        if (prevAuth) {
          setHasAdmin(true)
        }
      }
      checkAdmin()
    }
  }, [predeparture])

  return (
    <div className='card'>
      <div className='d-flex justify-content-center align-items-center card-header bg-primary rounded w-75 mx-auto mt-5'>
        <h1 className='fw-bolder fs-2x text-light text-uppercase'>Pre-Departure Tasks List</h1>
      </div>
      <div className='card-header my-5'>
        <p>
          Please have your parent or guardian fill out and submit these documents as soon as
          possible. Documents may take a few days to process after upload/submission. <br />
          <i>
            If you have been asked by {clientRoutes} staff to upload a document not listed below,
            please email your document to <a href={`mailto:${clientEmail}`}>{clientEmail}</a>
            .
          </i>
        </p>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 rounded-start'>Task Name</th>
                <th>Task Overview</th>
                <th>Status</th>
                <th>Deadline</th>
                <th>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            <tbody className='fs-5'>
              {predeparture?.map((p, i) => {
                  console.log('Predeparture Data', p);
                return (
                  <tr key={i}>
                    <td>
                      <span className='text-dark d-block ps-4'>{p.name}</span>
                    </td>
                    <td>
                      <span>{p.task_overview}</span>
                    </td>
                    <td>
                      <span className='text-dark d-block'>{p.status}</span>
                    </td>
                    <td style={{ minWidth: 150}}>
                      <span className='text-dark d-block'>{p.task_deadline}</span>
                    </td>
                    <td>
                      <div className=''>
                        {p.download_file_url && ( 
                        <a href={p.download_file_url} 
                          className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary me-2 min-w-150px'>
                          Download
                        </a>
                        )}
                        {p.status === 'Not Applicable' ? (
                          hasAdmin ? (
                            <Link
                              to={p.route_name?.replace(':client', currentUser?.currentClient || '19523902') + '/' + opportunity} // 19523902 is terraedu
                              state={{ predeparture: p }}
                              className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary min-w-150px'
                            >
                              {p.status.toLocaleLowerCase() === 'incomplete' ? 'Edit' : 'View'}
                            </Link>
                          ) : (
                            <div>Not Applicable</div>
                          )
                        ) : (
                          <Link
                            to={p.route_name?.replace(':client', currentUser?.currentClient || '19523902') + '/' + opportunity} // 19523902 is terraedu
                            state={{ predeparture: p }}
                            className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary min-w-150px'
                          >
                            {p.status.toLocaleLowerCase() === 'incomplete' ? 'Edit' : 'View'}
                          </Link>
                        )}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Predeparture
