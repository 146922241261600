import {useEffect, useState, useMemo} from 'react'
import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { stringifyRequestQuery } from "../../../../_metronic/helpers"
import DefaultSpinnerLoading from '../../Shared/components/loading/DefaultSpinnerLoading'
import { useQueryRequest } from "../../Shared/core/QueryRequestProvider"
import AddUpdateProduct from './components/AddUpdateProduct'
import _queryKeys from "./components/core/_queryKeys"
import { useProductRequests } from "./Hooks/useProductRequests"

const UpdateProductPage = () => {
  const [message, setMessage] = useState('')
  const {productId} = useParams()
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const {getProductDetailFieldsReq, saveProductDetailReq} = useProductRequests()
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {isLoading: isFieldLoading, isError: isFieldError } = useQuery(
    _queryKeys.getProductDetailFieldsReq,
    () => getProductDetailFieldsReq(productId)
  )  


  const {
    isLoading: isUpdateProductLoading,
    isSuccess: isUpdateProductSuccess,
    isError: isUpdateProductError,
    mutateAsync: updateProductByAdminAsync,
  } = useMutation(saveProductDetailReq)

  if (isFieldLoading) {
    return <DefaultSpinnerLoading />
  }

  if(isFieldError){
    return (
      <div className='card-body'>
        <div className='alert alert-dismissible bg-secondary d-flex align-items-center p-5'>
          <div className='d-flex flex-column '>
            <span className=''>Error while loading product detail!</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {isUpdateProductSuccess && (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{message}</span>
            </div>
          </div>
        </div>
      )}
      {isUpdateProductError && (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>Some error occurred while processing your request!</span>
            </div>
          </div>
        </div>
      )}
      <AddUpdateProduct
        isLoading={isUpdateProductLoading}
        setMessage={setMessage}
        productId={productId}
        updateProductByAdminAsync={updateProductByAdminAsync}
      />
    </>
  )
}

export default UpdateProductPage