import {useEffect, useState, useMemo} from 'react'
import {useMutation, useQuery} from 'react-query'
import {stringifyRequestQuery} from '../../../../_metronic/helpers'
import DefaultSpinnerLoading from '../../Shared/components/loading/DefaultSpinnerLoading'
import {useQueryRequest} from '../../Shared/core/QueryRequestProvider'
import AddUpdateProduct from './components/AddUpdateProduct'
import _queryKeys from './components/core/_queryKeys'
import {useProductRequests} from './Hooks/useProductRequests'

const AddProductPage = () => {
  const [message, setMessage] = useState('')
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const {getProductFieldsReq, addProductDetailReq} = useProductRequests()
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {isLoading: isFieldLoading, isError: isFieldError} = useQuery(
    _queryKeys.getProductFieldsReq,
    () => getProductFieldsReq()
  )
  
  const {
    isLoading: isAddProductLoading,
    isSuccess: isAddProductSuccess,
    isError: isAddProductError,
    mutateAsync: addProductByAdminAsync,
  } = useMutation(addProductDetailReq)

  if (isFieldLoading) {
    return <DefaultSpinnerLoading />
  }

  if (isFieldError) {
    return (
      <div className='card-body'>
        <div className='alert alert-dismissible bg-secondary d-flex align-items-center p-5'>
          <div className='d-flex flex-column '>
            <span className=''>Error while loading product detail!</span>
          </div>
        </div>
      </div>
    )
  }

    return (
      <>
        {isAddProductSuccess && (
          <div className='card-body'>
            <div className='alert alert-success d-flex align-items-center p-5'>
              <div className='d-flex flex-column '>
                <span>{message}</span>
              </div>
            </div>
          </div>
        )}
        {isAddProductError && (
          <div className='card-body'>
            <div className='alert alert-danger d-flex align-items-center p-5'>
              <div className='d-flex flex-column '>
                <span>Some error occurred while processing your request!</span>
              </div>
            </div>
          </div>
        )}
        <AddUpdateProduct
          isLoading={isAddProductLoading}
          setMessage={setMessage}
          addProductByAdminAsync={addProductByAdminAsync}
        />
      </>
    )
}

export default AddProductPage
