import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useParams} from 'react-router-dom'
import _queryKeys from './components/core/_queryKeys'
import AddUpdateOpportunity from './components/AddUpdateOpportunity'
import {useOpportunityRequests} from './Hooks/useOpportunityRequests'
import { useEffect, useMemo, useState } from 'react'
import { stringifyRequestQuery, QUERIES } from '../../../../_metronic/helpers'
import { useQueryRequest } from '../../Shared/core/QueryRequestProvider'
import { IOpportunity } from './components/core/_models'
import DefaultSpinnerLoading from '../../Shared/components/loading/DefaultSpinnerLoading'


const UpdateOpportunityPage = () => {
  const [message, setMessage] = useState('')
  const {opportunityId} = useParams()
  const queryClient = useQueryClient()
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const {getAllFormResponsesReq , saveOpportunityAndResponses} = useOpportunityRequests()
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  console.log(`${QUERIES.OPPORTUNITIES_LIST}-${query}`)

  const {
    isLoading: isFieldLoading,
  } = useQuery(_queryKeys.getAllFormResponsesReq, () => getAllFormResponsesReq(opportunityId))

  const oppsDetail = queryClient.getQueryData<{data: IOpportunity[]}>(`${QUERIES.OPPORTUNITIES_LIST}-${query}`)

  const oppDetail = oppsDetail?.data.filter((opp) => opp.id.toString() === opportunityId)
  // console.log('oppDetailsss', oppDetail)

  const {
    isLoading: isUpdateOpportunityLoading,
    isSuccess: isUpdateOpportunitySuccess,
    isError: isUpdateOpportunityError,
    mutateAsync: updateOpportunityByAdminAsync,
  } = useMutation(saveOpportunityAndResponses)

  if (isFieldLoading) {
    return (
      <DefaultSpinnerLoading/>
    )
  }

  return (
    <>
      {isUpdateOpportunitySuccess && (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{message}</span>
            </div>
          </div>
        </div>
      )}
      {isUpdateOpportunityError && (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>Some error occurred while processing your request!</span>
            </div>
          </div>
        </div>
      )}
      <AddUpdateOpportunity
        isLoading={isUpdateOpportunityLoading}
        oppDetail={oppDetail?.[0]}
        setMessage={setMessage}
        updateOpportunityByAdminAsync={updateOpportunityByAdminAsync}
      />
    </>
  )
}

export default UpdateOpportunityPage
