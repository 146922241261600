import {useListView} from '../core/OpportunitiesListViewProvider'
import {OppListToolbar} from './OppListToolbar'
import {OppListSearchComponent} from './OppListSearchComponent'

const OppListHeader = () => {
  useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <OppListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {<OppListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {OppListHeader}
