import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { axiosPrivate } from '../../../../../axiosInstance'

interface IProps {
  item: any
  refetch: any
}

const DeleteScholarship = ({ item, refetch }: IProps) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  const handleClose = () => {
    setShowModal(prev => !prev)
    setLoading(false)
    setErrorMessage('')
  }

  const onSubmit = async () => {
    try {
      setLoading(true)
      setErrorMessage('')
      setSuccessMessage('')
      await axiosPrivate.post('/user/remove_discount', item)
      setSuccessMessage('Successfully deleted. Please wait for a moment to allow the changes to be reflected')
      refetch()
      setTimeout(() => {
        handleClose()
      }, 1000)
    } catch (error) {
      setErrorMessage('Error while removing opportunity. Please try again later.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <span className='text-danger ms-4' style={{cursor: 'pointer'}} onClick={handleClose}>Delete</span>
      <Modal show={showModal} onHide={handleClose} backdrop='static' keyboard={false} centered>
        <Modal.Body className='p-0'>
          <Modal.Header closeButton>
              <Modal.Title>Are you sure you want to remove this scholarship?</Modal.Title>
          </Modal.Header>

          <p style={{margin: '20px 50px 20px 23px', fontSize: '16px'}}>This will remove this scholarship. You cannot undo this action.</p>

          {errorMessage ? (
            <div className='card-body'>
              <div className='alert alert-danger d-flex align-items-center px-5 py-3 mx-7'>
                <div className='d-flex flex-column '>
                  <span>{errorMessage}</span>
                </div>
              </div>
            </div>
          ) : ''}

          {successMessage ? (
            <div className='card-body'>
              <div className='alert alert-success d-flex align-items-center px-5 py-3 mx-7'>
                <div className='d-flex flex-column '>
                  <span>{successMessage}</span>
                </div>
              </div>
            </div>
          ) : ''}

          </Modal.Body>
          <Modal.Footer style={{padding: '20px 30px'}}>
            <Button variant='secondary' onClick={handleClose}>
              Discard
            </Button>
            <Button 
              variant='danger' 
              className='ms-2' 
              onClick={onSubmit}
            >
              {loading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : 'Remove'}
            </Button>
          </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeleteScholarship