import {Field, ErrorMessage} from 'formik'

const AdvisorOpt = () => {
  return (
    <>
      <div className='my-10'>
        <h4 className='fw-bold'>Advisor's Information</h4>
      </div>
      <div className='fv-row row mb-7'>
        <div className='form-label fw-bolder text-dark fs-6 required'>First Name</div>
        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name={'contactInfo.advisorInfo.firstName'}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={'contactInfo.advisorInfo.firstName'} />
        </div>
      </div>
      <div className='fv-row row mb-5'>
        <div className='form-label fw-bolder text-dark fs-6 required'>
          Last Name (Family Name)
        </div>
        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name={'contactInfo.advisorInfo.lastName'}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={'contactInfo.advisorInfo.lastName'} />
        </div>
      </div>
      <div className='fv-row row mb-7'>
        <div className='form-label fw-bolder text-dark fs-6 required'>
          Advisor's Email Address
        </div>
        <Field
          type='email'
          className='form-control form-control-lg form-control-solid'
          name={'contactInfo.advisorInfo.email'}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={'contactInfo.advisorInfo.email'} />
        </div>
      </div>
      <div className='fv-row row mb-7'>
        <span>
          The advisor's email address must be different from the studen't email address. Both must
          be valid since you will receive important communications related to your program at this
          address.
        </span>
      </div>
    </>
  )
}

export default AdvisorOpt
