import React from 'react'
import './style.css'
interface Props {
  title: string
  subheader?: string
  supportingHeader?: string
}

const ConfirmPayBanner = ({title, subheader, supportingHeader}: Props) => {
  return (
    <>
      <div
        className='d-flex justify-content-center rounded bg-primary w-75 mx-auto col-md-12'
        //style={{backgroundImage: `url(${background})`, backgroundSize: 'cover'}}
      >
        <div className='bg-opacity-50 w-xl-700px  text-center py-5 text-light border-radius-20'>
          <h1 className='fw-bolder fs-2x text-light text-uppercase'>{title}</h1>
          <div className='fw-bold text-uppercase mb-2'>{subheader}</div>
          <span className='text-uppercase'>{supportingHeader}</span>
        </div>
      </div>
    </>
  )
}

export default ConfirmPayBanner
