import {AxiosResponse, AxiosError} from 'axios'

export function setupAxiosStatus(axios: any, navigate: any, location: any) {
   axios.defaults.headers.Accept = 'application/json'
   axios.interceptors.response.use(
      (response: AxiosResponse) => {
         return response
      },
      (error: AxiosError) => {
         if (!error.response) {
            if (error.code === 'ERR_NETWORK') {
            //console.log('AxiosError', error.toString())
            console.log('Please check your internet connection.')
            return navigate('/error/503', {state: {from: location}, replace: true})
            }
            console.log('An unknown error occurred:', error.message);
            return Promise.reject(error);
         }
         //const {status} = error.response

         return Promise.reject(error)
      }
   )
}
