import {Field, ErrorMessage} from 'formik'

const PayByBankAccount = () => {
  return (
    <div className='row my-10'>
      <div className='col-lg-6'>
        <div className='d-flex flex-column mb-7 fv-row'>
          <div className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>Name On Account</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title="Specify a card holder's name"
            ></i>
          </div>

          <Field
            type='text'
            className='form-control form-control-solid'
            placeholder=''
            name='bankAccountInfo.nameOnCard'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='bankAccountInfo.nameOnCard' />
          </div>
        </div>
        <div className='d-flex flex-column mb-7 fv-row'>
          <div className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>Bank Routing Number</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title="Specify a card holder's name"
            ></i>
          </div>

          <Field
            type='text'
            className='form-control form-control-solid'
            placeholder=''
            name='bankAccountInfo.routingNumber'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='bankAccountInfo.routingNumber' />
          </div>
        </div>
        <div className='d-flex flex-column mb-7 fv-row'>
          <div className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>Bank Account Number</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title="Specify a card holder's name"
            ></i>
          </div>

          <Field
            type='text'
            className='form-control form-control-solid'
            placeholder=''
            name='bankAccountInfo.accountNumber'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='bankAccountInfo.accountNumber' />
          </div>
        </div>
        <div className='d-flex flex-column mb-7 fv-row'>
          <Field
            as='select'
            name='bankAccountInfo.bankType'
            className='form-select form-select-solid'
          >
            <option value='checking'>checking</option>
            <option value='savings'>savings</option>
          </Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='bankAccountInfo.bankType' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PayByBankAccount
