import {ListViewProvider} from '../../../Shared/core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {initialListView} from '../../../Shared/core/_models'

type Props = {
  children: JSX.Element
}

const UsersProvider = ({children}:Props) => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider initialState={initialListView}>
          {children}
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
} 

export default UsersProvider
