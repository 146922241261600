import {Formik} from 'formik'
import {Field, ErrorMessage} from 'formik'
import {
  IPayOpt,
  glaPaymentSchemasAddFund,
} from '../../core/_models'
import PaybyCreditCard from './PayOpt/PaybyCreditCard'
import PayByBankAccount from './PayOpt/PayByBankAccount'
import PaymentSchedule from './PayOpt/PaymentSchedule'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useParams, useNavigate} from 'react-router-dom'
import {useMutation} from 'react-query'
import useAppRequests from '../../Hooks/useAppRequests'
import {useAuth} from '../../../auth'
import {parse_payment} from '../../../../../utils/funcHelpers'
import {ChangeEvent, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import ErrorMessages from '../../../Shared/components/ErrorMessages'
import usePaymentInput from './useInputPayments'

const glaFee = 100
let initsPayOpt: IPayOpt = {
  paymentMethod: 'bankAccount',
  creditCardInfo: {
    nameOnCard: '',
    cardNumber: '',
    cardExpiryMonth: '',
    cardExpiryYear: '',
    cardCvv: '',
    saveCard: '1',
    billingAddress: '',
    billingCity: '',
    billingStateProvince: '',
    billingPostalCode: '',
    billingCountry: '',
  },
  bankAccountInfo: {
    nameOnCard: '',
    routingNumber: '',
    accountNumber: '',
    bankType: 'checkings',
  },
  sponsoredInfo: {
    sponsorCode: '',
  },
  parentSignatureTerms: '',
  opportunity: undefined,
  amount: 0,
  payment_schedule: '',
  totalAmount: 0,
  selectPMethod: undefined,
  comment: '',
}
const PayOpt = () => {
  const navigate = useNavigate()
  const {setupProgramsEnrollPayment, getOpportunityReq} = useAppRequests()
  const { client, opportunity } = useParams()
  const [paymentAmount, handleInputChange] = usePaymentInput();
  const [isStillFetching, setIsStillFetching] = useState(true)
  const {
    isLoading,
    isError: isSendPaymentError,
    mutate: sendPayment,
  } = useMutation(setupProgramsEnrollPayment)

  const [sendPaymentError, setSendPaymentError] = useState<any>(null)

  const {currentUser} = useAuth()
  const { data, error, isLoading: opportunityLoading, remove } = useQuery('', () => getOpportunityReq(client, Number(opportunity)));

  const enrollmentSummary: any = data || {};

  let totalDuenow = 0
  const totalDue = enrollmentSummary?.remaining_balance
  
  useEffect(() => {
    if(enrollmentSummary.customer && enrollmentSummary.fundings) {
      const currentPMethod = (!enrollmentSummary) ? null : enrollmentSummary?.fundings?.find((pm : any) => pm.isActive)
      initsPayOpt.paymentMethod =  currentPMethod
        ? currentPMethod?.paymentType
        : initsPayOpt.paymentMethod || "bankAccount"
      initsPayOpt.selectPMethod = currentPMethod
        ? `funding_${currentPMethod.paymentType}_${currentPMethod?.id}`
        : undefined
      setIsStillFetching(false)
    }
  }, [enrollmentSummary])
  
  const onSubmitPayment = (values: IPayOpt): any => {
    values.opportunity = enrollmentSummary?.id
    values.totalAmount = Number(enrollmentSummary?.remaining_balance) || 0
    values.isCustomPay = true
    // values.amount = totalDuenow || 0

    sendPayment(values, {
      onSuccess: () => {
        remove()
        console.log('go to', '/enroll-part-two')
        return navigate(
          `/dashboard/${currentUser?.currentClient}`,
          {
            replace: true,
            state: { amountPaid: values.amount}
          }
        )
      },
      onError: (err: any) => {
        console.log('payment error', err.response)
        const message = err.response?.data.message
        setSendPaymentError(message)
      },
    })
  }

  const setPaymentMethod = (e: any, setFieldValue: any): any => {
    e.currentTarget.id === 'creditCard'
      ? setFieldValue('paymentMethod', 'creditCard')
      : e.currentTarget.id === 'bankAccount'
      ? setFieldValue('paymentMethod', 'bankAccount')
      : setFieldValue('paymentMethod', 'sponsored')
  }

  const setAmount = (e: any, setFieldValue: any): any => {
    if (e.currentTarget.id === 'customAmount') {
        setFieldValue('amount', totalDuenow)
    } else if (e.currentTarget.id === 'fullamount') {
        setFieldValue('amount', Number(totalDue))
        console.log('amount', totalDue)
    } else {
        setFieldValue('amount', 0)
    }
  }

  let tabClicked = true
  const setScheduleOptions = (event: any) => {
    if (!!event) {
      tabClicked = true
    } else {
      tabClicked = false
    }
  }
  
  const pMethodSaved = (!enrollmentSummary) ? null : enrollmentSummary?.fundings?.filter((pm : any) => pm.isActive)
  const pMethodSavedCredit = (!enrollmentSummary) ? null : enrollmentSummary?.fundings?.filter((pm : any) => pm.paymentType === 'creditCard')
  const pMethodSavedACH = (!enrollmentSummary) ? null : enrollmentSummary?.fundings?.filter((pm : any) => pm.paymentType === 'bankAccount')
  let ccCount = 0
  let ACHCount = 0
  if(pMethodSavedCredit)  ccCount = pMethodSavedCredit.length
  if(pMethodSavedACH) ACHCount = pMethodSavedACH.length
  // const pMethodSavedText = pMethodSaved ? parse_payment(pMethodSaved[0]) : null
  // set pMethodSaved as default payment
  return (
    <div className='mt-10'>
      {enrollmentSummary && !isStillFetching ? (
        <Formik
          validationSchema={glaPaymentSchemasAddFund}
          initialValues={initsPayOpt}
          onSubmit={onSubmitPayment}
        >
          {({values, setFieldValue, errors, setErrors, handleSubmit}) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className='d-flex flex-column'>
                  <h2>Payment method</h2>
                  <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6 nav nav-tabs nav-line-tabs mb-5 fs-6 payment-method-nav-tabs show active '>
                    <li className='nav-item col-lg-6'>
                      <a
                        id='bankAccount'
                        className={values.paymentMethod === 'bankAccount' ? 'nav-link active' : 'nav-link'}
                        data-bs-toggle='tab'
                        href='#pmt_ach'
                        onClick={(e) => setPaymentMethod(e, setFieldValue)}
                      >
                        BANK ACCOUNT (ACH PAYMENT)
                      </a>
                    </li>
                    <li className='nav-item col-lg-6'>
                      <a
                        id='creditCard'
                        className={values.paymentMethod === 'creditCard' ? 'nav-link active' : 'nav-link'}
                        data-bs-toggle='tab'
                        href='#pmt_credit_card'
                        onClick={(e) => setPaymentMethod(e, setFieldValue)}
                      >
                        CREDIT CARD
                      </a>
                    </li>
                  </ul>
                  <div className='tab-content' id='myTabContent'>
                    <div className={values.paymentMethod === 'bankAccount' ? 'tab-pane fade show active' : 'tab-pane fade'} id='pmt_ach' role='tabpanel'>
                      {pMethodSaved && pMethodSaved.map((data: any) => {return data.paymentType  === 'bankAccount'}) && ACHCount > 0 ? 
                          (<>
                              <div className='d-flex flex-column my-5'>
                                {pMethodSavedACH?.map((data: any) => {
                                  const dataSaveText = data ? parse_payment(data) : null
                                  return (
                                      <div className='mb-7 me-5'>
                                      <Field
                                        type='radio'
                                        className='form-check-input me-2'
                                        value={`funding_${data.paymentType}_${data?.id}`}
                                        name={'selectPMethod'}
                                        id={`funding_${data.paymentType}_${data?.id}`}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor={`funding_${data.paymentType}_${data?.id}`}
                                      >
                                        <i className={dataSaveText?.icon}></i> {dataSaveText?.value}{' '}
                                        Ending in {data?.cardNumber?.replaceAll('x', '')}
                                      </label>
                                    </div>
                                  )
                                })}
                                <div className='mb-7 me-5'>
                                  <Field
                                    type='radio'
                                    className='form-check-input me-2'
                                    value={'bankAccount'}
                                    name={'selectPMethod'}
                                    id={'bankAccount_selectPMethod'}
                                  />
                                  <label className='form-check-label' htmlFor={'bankAccount_selectPMethod'}>
                                    Use a new payment method
                                  </label>
                                </div>
                              </div>
                              {values.selectPMethod === 'bankAccount' && <PayByBankAccount />}
                            </>
                            ) : (
                        <PayByBankAccount />
                        )}
                    </div>
                    <div className={values.paymentMethod === 'creditCard' ? 'tab-pane fade show active' : 'tab-pane fade'} id='pmt_credit_card' role='tabpanel'>
                    {pMethodSaved && pMethodSaved.map((data: any) => { return data.paymentType  === 'creditCard'})  && ccCount > 0 ? (
                          <>
                            <div className='d-flex flex-column my-5'>
                            {pMethodSavedCredit?.map((data: any) => {
                                  const dataSaveText = data ? parse_payment(data) : null
                                  return (
                                      <div className='mb-7 me-5'>
                                      <Field
                                        type='radio'
                                        className='form-check-input me-2'
                                        value={`funding_${data.paymentType}_${data?.id}`}
                                        name={'selectPMethod'}
                                        id={`funding_${data.paymentType}_${data?.id}`}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor={`funding_${data.paymentType}_${data?.id}`}
                                      >
                                        <i className={dataSaveText?.icon}></i> {dataSaveText?.value}{' '}
                                        Ending in {data?.cardNumber?.replaceAll('x', '')}
                                      </label>
                                    </div>
                                  )
                                })}

                              <div className='mb-7 me-5'>
                                <Field
                                  type='radio'
                                  className='form-check-input me-2'
                                  value={'creditCard'}
                                  name={'selectPMethod'}
                                  id={'creditCard_selectPMethod'}
                                />
                                <label className='form-check-label' htmlFor={'creditCard'}>
                                  Use a new payment method
                                </label>
                              </div>
                            </div>
                            {values.selectPMethod === 'creditCard' && <PaybyCreditCard  currentFormikVal={values}/>}
                          </>
                        ) : (
                        <PaybyCreditCard  currentFormikVal={values}/>
                      )}
                    </div>
                  </div>

                    <h2>Payment amount</h2>
                    <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6 payment-method-nav-tabs'>
                      {client === '47821104' ? (
                        <li className='nav-item col-lg-4' onClick={() => setScheduleOptions(true)}>
                        <a
                          id='paymentSchedule'
                          className='nav-link'
                          data-bs-toggle='tab'
                          href='#amt_schedule'
                          onClick={(e) => setAmount(e, setFieldValue)}
                        >
                          PAYMENT SCHEDULE
                        </a>
                      </li>
                      ) : ""}
                      <li className='nav-item col-lg-4' onClick={() => setScheduleOptions(false)}>
                        <a
                          id='customAmount'
                          className='nav-link active'
                          data-bs-toggle='tab'
                          href='#amt_custom'
                          onClick={(e) => setAmount(e, setFieldValue)}
                        >
                          CHOOSE AMOUNT
                        </a>
                      </li>
                      <li className='nav-item col-lg-4' onClick={() => setScheduleOptions(false)}>
                        <a
                          id='fullamount'
                          className='nav-link'
                          data-bs-toggle='tab'
                          href='#amt_full'
                          onClick={(e) => setAmount(e, setFieldValue)}
                        >
                          PAY IN FULL
                        </a>
                      </li>
                    </ul>
                    <div className='tab-content' id='myTabContent'>
                      <div className='tab-pane fade' id='amt_schedule' role='tabpanel'>
                          {tabClicked == true && <PaymentSchedule />}
                      </div>
                      <div className='tab-pane fade show active' id='amt_custom' role='tabpanel'>
                          {opportunityLoading ? (
                            <div className='d-flex justify-content-center align-items-center m-auto'>
                              <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          ): (
                            <div className='col-lg-4 col-md-4 d-flex flex-column m-auto'>
                              <p>Other amount</p>
                                <Field
                                type='text'
                                className='form-control form-control-solid'
                                placeholder=''
                                name='amount'
                                value={paymentAmount}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                  handleInputChange(e.target.value)
                                  setFieldValue('amount', e.target.value)
                                }}
                                maxLength={12}
                              />
                              <p>
                                Enter an amount between the deposit (${totalDuenow}) and the full amount ($
                                {totalDue})
                              </p>
                            </div>
                          )}
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='amount' />
                        </div>
                      </div>
                      <div className='tab-pane fade' id='amt_full' role='tabpanel'>
                        <div className='col-lg-4 col-md-4 d-flex flex-column m-auto'>
                          <p>Pay the full remaining balance of ${totalDue}</p>
                        </div>
                      </div>
                    </div>

                  <div className='text-danger mt-2'>
                    <span>{errors.paymentMethod}</span>
                    <ErrorMessage name='paymentMethod' />
                  </div>
                  {/* <PaymentTerms /> */}
                  {/* <div className='row bg-success py-10'>
                    <div className='col-lg-6'>
                      <Field
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='Parent: Write your name here *'
                        name='parentSignatureTerms'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='parentSignatureTerms' />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      By writing your name here, you are validating that you have read and agree to
                      the above Terms & Conditions.
                    </div>
                  </div> */}
                  <ErrorMessages isError={isSendPaymentError} messages={sendPaymentError} />
                  <div className='d-flex justify-content-end my-10'>
                    <div className='d-flex align-items-center'>
                      {/* <span className='fs-2 me-5'>Reserve your spot!</span> */}
                      <button type='submit' className='btn btn-lg btn-primary me-3' disabled={isLoading}>
                        {!isLoading && (
                          <span className='indicator-label'>
                            Next Step
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </span>
                        )}
                        {isLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )
          }}
        </Formik>
      ): (
        <div className='w-100 my-10'>
          <div className='d-flex justify-content-center align-items-center'>
            <span className='me-5'>Please wait...</span>
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        </div>
      )}
  </div>
  )
}

export default PayOpt
