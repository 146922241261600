import {FC, useState, createContext, useContext, useMemo} from 'react'
import {
  ID,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  initialListView,
  groupingOnSelectAll,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import {useQueryResponse, useQueryResponseData} from './QueryResponseProvider'
import { OpportunitiesListViewContextProps } from './_models'

const ListViewContext = createContext<OpportunitiesListViewContextProps>(initialListView)

const OpportunitiesListViewProvider: FC<WithChildren> = ({children}) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const {isLoading} = useQueryResponse()
  const data = useQueryResponseData()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])

  const value = useMemo(() => ({
    selected,
    disabled,
    isAllSelected,
    onSelect: (id: ID) => {
      groupingOnSelect(id, selected, setSelected)
    },
    onSelectAll: () => {
      groupingOnSelectAll(isAllSelected, setSelected, data)
    },
    clearSelected: () => {
      setSelected([])
    },
  }), [selected, disabled, isAllSelected, data])

  return (
    <ListViewContext.Provider value={value}>
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {OpportunitiesListViewProvider, useListView}
