import React, {useEffect, useState, SetStateAction, Dispatch} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik, FormikValues} from 'formik'
import {initialPayment, IPaymentMethod, IPaymentMethods, PaymentMethod} from '../../core/_models'
import {UseMutateAsyncFunction, useQueryClient} from 'react-query'
import {isNotEmpty, KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {COUNTRIES_ISO_3166_ALPHA_3} from '../../../../../utils'
import _glaQueryKeys from '../../core/_queryKeys'

type Props = {
  id: string | undefined
  close: any
  isPaymentLoading: boolean
  payment_method: IPaymentMethod | undefined
  setMessage: Dispatch<SetStateAction<object>>
  paymentMutateAsync?: {
    createPMethodByAdminAsync?: UseMutateAsyncFunction<
      IPaymentMethods,
      unknown,
      IPaymentMethod,
      unknown
    >
    updatePMethodByAdminAsync?: UseMutateAsyncFunction<
      IPaymentMethods,
      unknown,
      IPaymentMethod,
      unknown
    >
    delete?: UseMutateAsyncFunction<IPaymentMethods, unknown, number | null | undefined, unknown>
  }
}

const PaymentModal = ({
  id,
  close,
  isPaymentLoading,
  payment_method,
  setMessage,
  paymentMutateAsync,
}: Props) => {
  const queryClient = useQueryClient()
  const [selected, setSelected] = useState('')
  const [currentFormikVal, setCurrentFormikVal] = useState(initialPayment)
  const addOrEditPaymentSchema = Yup.object().shape({
    paymentType: Yup.string()
      //.oneOf(['bankAccount', 'creditCard'])
      .required('Please choose a option.'),
    // email: Yup.string()
    //   .email('Wrong email format')
    //   .min(3, 'Minimum 3 symbols')
    //   .max(50, 'Maximum 50 symbols')
    //   .required('Email is required'),
    // : Yup.string().oneOf(Object.values(ROLES)).required('Please choose a role.'),
    // clientName:
    //   user?.id || currentFormikVal.role === 'admin' || currentFormikVal.role === 'superadmin'
    //     ? Yup.array().nullable()
    //     : Yup.array().of(Yup.string()).min(1, 'Please choose a program'),
    // contactInfo: currentFormikVal.role === 'student' ? studentInfoSchema : Yup.object().nullable(),
  })
  const [paymentToUpdate] = useState<PaymentMethod>({
    id: payment_method?.id,
    paymentType: payment_method?.paymentType || initialPayment.paymentType,
    isActive: payment_method?.isActive || initialPayment.isActive,
    subType: payment_method?.subType || undefined,
    creditCardInfo: {
      nameOnCard: payment_method?.account_name || '',
      cardNumber: payment_method?.cardNumber || '',
      cardExpiryMonth: payment_method?.expireDates?.split('/')[0] || '',
      cardExpiryYear: '20' + payment_method?.expireDates?.split('/')[1] || '',
      cardCvv: '',
      saveCard: '',
      billingAddress: payment_method?.address?.address || '',
      billingCity: payment_method?.address?.city || '',
      billingStateProvince: payment_method?.address?.stateProvince || '',
      billingPostalCode: payment_method?.address?.postalCode || '',
      billingCountry: payment_method?.address?.country || '',
    },
    bankAccountInfo: {
      nameOnCard: payment_method?.account_name || '',
      routingNumber: payment_method?.routingNumber || '',
      accountNumber: payment_method?.accountNumber || '',
      bankType: payment_method?.subType || 'checking',
    },
  })
  
  const onSubmit = async (values: any, actions: FormikValues) => {
    actions.setSubmitting(true)
    try {
      if (isNotEmpty(values.id) && paymentMutateAsync?.updatePMethodByAdminAsync) {
        const res = await paymentMutateAsync.updatePMethodByAdminAsync(values)
        if (res) {
          setMessage({statusCode: true, message: 'Payment method has been updated successfully!'})
        }
      } else if (paymentMutateAsync?.createPMethodByAdminAsync) {
        const res = await paymentMutateAsync?.createPMethodByAdminAsync(values)
        if (res) {
          setMessage({
            message: 'Payment method has been added successfully!',
            statusCode: true
          })
        } else {
          setMessage({
            message: 'Payment method Already Exists!',
            statusCode: false
          })
        }
      }
      await queryClient.prefetchQuery(_glaQueryKeys.getUserPaymentsReq)
    } catch (ex) {
      console.error(ex)
    } finally {
      actions.setSubmitting(true)
      cancel(true)
    }
  }


  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const cancel = (withRefresh?: boolean) => {
    // if (withRefresh) {
    //   refetch()
    // }
    console.log('CANCELLED?')
    close(undefined)
  }


  console.log(paymentToUpdate, '@@@@@')

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered modal-xl'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder'>Payment</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => close(null)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <Formik
                validationSchema={addOrEditPaymentSchema}
                initialValues={paymentToUpdate}
                onSubmit={onSubmit}
              >
                {({values, errors, setFieldValue, isSubmitting, isValid, touched}) => {
                  console.log('formikvalues', values)
                  console.log('formikerrors', errors)
                  setCurrentFormikVal(values)

                  const changeSelectOptionHandler = (e: React.ChangeEvent<HTMLSelectElement>): void => {
                    const countryValue = e.target.value;
                    setSelected(countryValue);
                    setFieldValue('creditCardInfo.billingCountry', countryValue);
                    setFieldValue('creditCardInfo.billingStateProvince', '');
                  };
                  
                
                  return (
                    <Form>
                      <div className='row fv-row'>
                        <label className='required fs-6 fw-bold form-label mb-2'>
                          Payment Method
                        </label>
                        <div className='col-6'>
                          <Field
                            as='select'
                            name='paymentType'
                            className='form-select form-select-solid'
                          >
                            <option value=''>Select..</option>
                            <option value='creditCard'>Credit Card</option>
                            <option value='bankAccount'>Checking Account</option>
                          </Field>
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='paymentType' />
                          </div>
                        </div>
                      </div>
                      {values.paymentType != undefined && values.paymentType == 'creditCard' && (
                        <>
                          <div className='row my-10'>
                            <div className='d-flex flex-column mb-7 fv-row'>
                              <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                <span className='required'>Name On Card</span>
                                <i
                                  className='fas fa-exclamation-circle ms-2 fs-7'
                                  data-bs-toggle='tooltip'
                                  title="Specify a card holder's name"
                                ></i>
                              </label>

                              <Field
                                type='text'
                                className='form-control form-control-solid'
                                placeholder=''
                                name='creditCardInfo.nameOnCard'
                              />
                              <div className='text-danger mt-2'>
                                <ErrorMessage name='creditCardInfo.nameOnCard' />
                              </div>
                            </div>
                            {values.id === undefined && (
                              <div className='d-flex flex-column mb-7 fv-row'>
                                <label className='required fs-6 fw-bold form-label mb-2'>
                                  Card Number
                                </label>

                                <div className='position-relative'>
                                  <Field
                                    type='text'
                                    display={values.id ? false : true}
                                    // className={clsx("btn btn-sm", pm.isActive ? "btn-outline-success" : "btn-outline-secondary")}
                                    className='form-control form-control-solid data-gray-class'
                                    placeholder='Enter card number'
                                    name='creditCardInfo.cardNumber'
                                  />
                                  <div className='text-danger mt-2'>
                                    <ErrorMessage name='creditCardInfo.cardNumber' />
                                  </div>

                                  <div className='position-absolute translate-middle-y top-50 end-0 me-5'>
                                    <img
                                      src={toAbsoluteUrl('/media/svg/card-logos/visa.svg')}
                                      alt=''
                                      className='h-25px'
                                    />
                                    <img
                                      src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}
                                      alt=''
                                      className='h-25px'
                                    />
                                    <img
                                      src={toAbsoluteUrl(
                                        '/media/svg/card-logos/american-express.svg'
                                      )}
                                      alt=''
                                      className='h-25px'
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className='row mb-10'>
                              <div className='col-md-8 fv-row'>
                                <label className='required fs-6 fw-bold form-label mb-2'>
                                  Expiration Date
                                </label>

                                <div className='row fv-row'>
                                  <div className='col-6'>
                                    <Field
                                      as='select'
                                      name='creditCardInfo.cardExpiryMonth'
                                      className='form-select form-select-solid'
                                    >
                                      <option></option>
                                      <option value='1'>1</option>
                                      <option value='2'>2</option>
                                      <option value='3'>3</option>
                                      <option value='4'>4</option>
                                      <option value='5'>5</option>
                                      <option value='6'>6</option>
                                      <option value='7'>7</option>
                                      <option value='8'>8</option>
                                      <option value='9'>9</option>
                                      <option value='10'>10</option>
                                      <option value='11'>11</option>
                                      <option value='12'>12</option>
                                    </Field>
                                    <div className='text-danger mt-2'>
                                      <ErrorMessage name='creditCardInfo.cardExpiryMonth' />
                                    </div>
                                  </div>

                                  <div className='col-6'>
                                    <Field
                                      as='select'
                                      name='creditCardInfo.cardExpiryYear'
                                      className='form-select form-select-solid'
                                    >
                                      <option></option>
                                      <option value='2021'>2021</option>
                                      <option value='2022'>2022</option>
                                      <option value='2023'>2023</option>
                                      <option value='2024'>2024</option>
                                      <option value='2025'>2025</option>
                                      <option value='2026'>2026</option>
                                      <option value='2027'>2027</option>
                                      <option value='2028'>2028</option>
                                      <option value='2029'>2029</option>
                                      <option value='2030'>2030</option>
                                      <option value='2031'>2031</option>
                                    </Field>
                                    <div className='text-danger mt-2'>
                                      <ErrorMessage name='creditCardInfo.cardExpiryYear' />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {values.id === undefined && (
                                <div className='col-md-4 fv-row'>
                                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                    <span className='required'>CVV</span>
                                    <i
                                      className='fas fa-exclamation-circle ms-2 fs-7'
                                      data-bs-toggle='tooltip'
                                      title='Enter a card CVV code'
                                    ></i>
                                  </label>
                                  <div className='position-relative'>
                                    <Field
                                      type='text'
                                      className='form-control form-control-solid'
                                      minLength={3}
                                      maxLength={4}
                                      placeholder='CVV'
                                      name='creditCardInfo.cardCvv'
                                    />
                                    <div className='text-danger mt-2'>
                                      <ErrorMessage name='creditCardInfo.cardCvv' />
                                    </div>
                                    <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
                                      <KTSVG
                                        path='/media/icons/duotune/finance/fin002.svg'
                                        className='svg-icon-2hx'
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='row my-10'>
                            <div className='d-flex flex-column mb-7 fv-row'>
                              <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                <span className='required'>Billing Address</span>
                              </label>

                              <Field
                                type='text'
                                className='form-control form-control-solid'
                                placeholder=''
                                name='creditCardInfo.billingAddress'
                              />
                              <div className='text-danger mt-2'>
                                <ErrorMessage name='address.billingAddress' />
                              </div>
                            </div>
                            <div className='d-flex flex-column mb-7 fv-row'>
                              <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                <span className='required'>Billing City</span>
                              </label>

                              <Field
                                type='text'
                                className='form-control form-control-solid'
                                placeholder=''
                                name='creditCardInfo.billingCity'
                              />
                              <div className='text-danger mt-2'>
                                <ErrorMessage name='creditCardInfo.billingCity' />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-lg-4'>
                                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                  <span className='required'>Billing Postal Code</span>
                                </label>
                                <Field
                                  type='text'
                                  className='form-control form-control-solid'
                                  placeholder=''
                                  name='creditCardInfo.billingPostalCode'
                                />
                                <div className='text-danger mt-2'>
                                  <ErrorMessage name='creditCardInfo.billingPostalCode' />
                                </div>
                              </div>
                              <div className='col-lg-4'>
                                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                  <span className='required'>Billing Country</span>
                                </label>
                                <Field
                                  as='select'
                                  name='creditCardInfo.billingCountry'
                                  className='form-select form-select-lg form-select-solid'
                                  onChange={changeSelectOptionHandler}
                                >
                                  <option>Select Country</option>
                                  {COUNTRIES_ISO_3166_ALPHA_3.map((c, idx) => {
                                    return (
                                      <option value={c.alpha_3} key={idx}>
                                        {c.name}
                                      </option>
                                    )
                                  })}
                                </Field>
                                <div className='text-danger mt-2'>
                                  <ErrorMessage name='creditCardInfo.billingCountry' />
                                </div>
                              </div>
                              {selected && 
                                <div className='col-lg-4'>
                                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                  <span className='required'>Billing State/Province</span>
                                </label>
                                <Field
                                  as='select'
                                  name='creditCardInfo.billingStateProvince'
                                  className='form-select form-select-lg form-select-solid'
                                >
                                  <option>Select State/Province</option>
                                  {COUNTRIES_ISO_3166_ALPHA_3.map((c, idx) => {
                                    if (c.alpha_3 == selected) {
                                      let provinces = c.divisions?.map((d, id) => {
                                        return (
                                          <option value={d.code} key={id}>
                                            {d.name}
                                          </option>
                                        )
                                      })
                                      return provinces
                                    }
                                  })}
                                </Field>
                                <div className='text-danger mt-2'>
                                  <ErrorMessage name='creditCardInfo.billingStateProvince' />
                                </div>
                              </div>
                            }
                            </div>
                          </div>
                        </>
                      )}
                      {values.paymentType != undefined && values.paymentType == 'bankAccount' && (
                        <>
                          <div className='row my-10'>
                            <div className='col-lg-6'>
                              <div className='d-flex flex-column mb-7 fv-row'>
                                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                  <span className='required'>Name On Account</span>
                                  <i
                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                    data-bs-toggle='tooltip'
                                    title="Specify a card holder's name"
                                  ></i>
                                </label>

                                <Field
                                  type='text'
                                  className='form-control form-control-solid'
                                  placeholder=''
                                  name='bankAccountInfo.nameOnCard'
                                />
                                <div className='text-danger mt-2'>
                                  <ErrorMessage name='bankAccountInfo.nameOnCard' />
                                </div>
                              </div>
                              <div className='d-flex flex-column mb-7 fv-row'>
                                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                  <span className='required'>Bank Routing Number</span>
                                  <i
                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                    data-bs-toggle='tooltip'
                                    title="Specify a card holder's name"
                                  ></i>
                                </label>

                                <Field
                                  type='text'
                                  className='form-control form-control-solid'
                                  placeholder=''
                                  name='bankAccountInfo.routingNumber'
                                />
                                <div className='text-danger mt-2'>
                                  <ErrorMessage name='bankAccountInfo.routingNumber' />
                                </div>
                              </div>
                              <div className='d-flex flex-column mb-7 fv-row'>
                                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                  <span className='required'>Bank Account Number</span>
                                  <i
                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                    data-bs-toggle='tooltip'
                                    title="Specify a card holder's name"
                                  ></i>
                                </label>

                                <Field
                                  type='text'
                                  className='form-control form-control-solid'
                                  placeholder=''
                                  name='bankAccountInfo.accountNumber'
                                />
                                <div className='text-danger mt-2'>
                                  <ErrorMessage name='bankAccountInfo.accountNumber' />
                                </div>
                              </div>
                              <div className='d-flex flex-column mb-7 fv-row'>
                                <Field
                                  as='select'
                                  name='bankAccountInfo.bankType'
                                  className='form-select form-select-solid'
                                >
                                  <option value='checking'>checking</option>
                                  <option value='savings'>savings</option>
                                </Field>
                                <div className='text-danger mt-2'>
                                  <ErrorMessage name='bankAccountInfo.bankType' />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {/* end::Modal body */}
                      <div className='modal-footer'>
                        <button
                          type='reset'
                          onClick={() => cancel()}
                          className='btn btn-light'
                          data-bs-dismiss='modal'
                        >
                          Discard
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary'
                          data-kt-users-modal-action='submit'
                          disabled={isPaymentLoading || isSubmitting || !isValid || !touched}
                        >
                          {!isPaymentLoading && <span className='indicator-label'>Submit</span>}
                          {isPaymentLoading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export default PaymentModal
