import {useEffect, useMemo, useState} from 'react'
import {PREV_AUTH_LOCAL_STORAGE_KEY, getAuthPromise, setAuthPromise, useAuth} from '../../auth'
import {useNavigate} from 'react-router-dom'
import {exportWaitlist, getAllWaitlisted} from '../../../../network/api'
import moment from 'moment'
import { KTSVG } from '../../../../_metronic/helpers'
import { axiosPrivate } from '../../../../axiosInstance'
import EnrollAction from './components/EnrollActionButton'
import DeclineAction from './components/DeclineActionButton'
import Filter from './components/Filter'
import { PiExport } from 'react-icons/pi'
import DynamicTableV2 from '../../../../_metronic/shared-components/dynamic-table-v2'


interface ILoginUser {
  login_id: number
  role_id: number
  login_email: string
  uc_id: number
}

const Waitlist = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const [progressLoading, setProgressLoading] = useState(false)
  const [progressData, setProgressData] = useState<any>(null)

  // STATES
  const [progress, setProgress] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  const [visibleData, setVisibleData] = useState<any[]>([])
  const [search, setSearch] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [refresh, setRefresh] = useState(0)
  const [successDelete, setSuccessDelete] = useState({
    data: false,
    message: '' || null
  })
  const [dates, setDates] = useState({
    createAt: '',
    createTo: ''
  })
  useEffect(() => {
    setLoading(true)
    const getAllWaitlistUser = async () => {
      const response = await getAllWaitlisted()
      setProgress(response)
      setLoading(false)
    }

    getAllWaitlistUser()
  }, [refresh])

  const getExportProgress = async () => {
    setProgressLoading(true)
    const res = await exportWaitlist(search, dates.createAt,dates.createTo)
    setProgressData(res)
    setProgressLoading(false)
  }

  const loginUser = async ({login_id, role_id, login_email, uc_id}: ILoginUser) => {
    setLoading(true)
    const currentAuth = await getAuthPromise()
    const {data: auth} = await axiosPrivate.post('/auth/login_user_by_admin', {email: login_email})
  
    if (auth.api_token && currentAuth) {
      currentAuth.email = currentUser?.email
      currentAuth.first_name = currentUser?.firstName || ''
      currentAuth.last_name = currentUser?.lastName || ''

      const promises = [
        setAuthPromise(currentAuth, PREV_AUTH_LOCAL_STORAGE_KEY),
        setAuthPromise(auth)
      ]

      Promise.all(promises)
        .then(async () => {
          const admin_body = {
            first_name: currentUser?.userclient.first_name || '',
            last_name: currentUser?.userclient.last_name || '',
            email: currentUser?.email,
            loginuser_id: login_id,
            userclient_id: uc_id,
            role_id,
            action: 'signed-in',
          }
          return await axiosPrivate.post('/add_admin_logs', admin_body)
        })
        .then(() => {
          window.location.href = '/';
        })
        .catch((error) => {
          console.error("Error setting auth:", error);
        });
    }
  }

  useEffect(() => {
    const filteredProgress = search.length !== 0 && (dates.createAt.length !== 0 && dates.createTo.length !== 0)
    ? progress.filter(item =>
      moment(item?.created_at).isBetween(moment(dates.createAt),moment(dates.createTo), null, '[]')
      &&
        (item?.first_name?.toLowerCase().includes(search.toLowerCase())
        || item?.last_name?.toLowerCase().includes(search.toLowerCase())
        || item?.program_summary?.toLowerCase().includes(search.toLowerCase())
        || item?.program_track_name?.toLowerCase().includes(search.toLowerCase()))) 
    : search.length !== 0 || (dates.createAt.length !== 0 && dates.createTo.length !== 0) ?
      search.length !== 0 ?
        progress.filter(item =>
          item?.first_name?.toLowerCase().includes(search.toLowerCase())
          || item?.last_name?.toLowerCase().includes(search.toLowerCase())
          || item?.program_summary?.toLowerCase().includes(search.toLowerCase())
          || item?.program_track_name?.toLowerCase().includes(search.toLowerCase()))
        : dates.createAt.length !== 0 && dates.createTo.length !== 0 ?
          progress.filter(item => moment(item?.created_at).isBetween(moment(dates.createAt),moment(dates.createTo), null, '[]'))
      : progress : progress

    setVisibleData(filteredProgress)
  }, [progress, search, dates])


  const handleOpenAction = (id: any) => {
    navigate(`/apps/${currentUser?.currentClient}/waitist-management/waitlist-user`)
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Date Submitted',
        accessor: 'created_at',
        Cell: ({value}: any) => {
          const formattedDate = moment(value).format('MM/DD/YYYY hh:mm:ss A')
          return formattedDate
        },
        minWidth: 120,
      },
      {
        Header: 'Last Name',
        accessor: 'last_name',
        Cell: ({value}: any) => {
          return value || 'N/A'
        }
      },
      {
        Header: 'First Name',
        accessor: 'first_name',
        Cell: ({value}: any) => {
          return value || 'N/A'
        }
      },
      {
        Header: 'Enrollment Option',
        accessor: 'program_summary',
        minWidth: 100,
      },
      {
        Header: 'Sessions',
        accessor: 'program_track_name',
        minWidth: 10,
      },
      {
        Header: 'Gender',
        accessor: 'sex_assigned_at_birth',
        Cell: ({value}: any) => {
          return value || 'N/A'
        }
      },
      {
        Header: 'Age',
        accessor: 'date_of_birth',
        Cell: ({value}: any) => {
          const birthDate = moment(value)
          const currentDate = moment()
          const age = currentDate.diff(birthDate, 'years')
          return age || 0
        }
      },
      {
        Header: 'Grade',
        accessor: 'grade_entering_fall',
        Cell: ({value}: any) => {
          return value || 'N/A'
        }
      },
      {
        Header: 'Actions',
        accessor: 'opp_id',
        disableSortBy: true,
        Cell: (props: any) => {
          const total_capacity = props.row.original.capacity_total
          const capacity_raw = +props.row.original.capacity
          const capacity = isNaN(capacity_raw) ? 999 : capacity_raw
          const isFull = total_capacity >= capacity
          return (
            <>
            <a
              href='javascript:void(0)'
              onClick={() => {
                handleOpenAction(props.row.original.opp_id)
              }}
              className='btn btn-light btn-active-light-primary btn-sm d-flex flex-row'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              id={`action_button_` + props.row.original.opp_id}
            >
              Actions
              <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
            </a>
            {/* begin::Menu */}
            <div
              id={'subMenu_' + props.row.original.opp_id}
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
              data-kt-menu='true'
            >
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <a
                  className='menu-link px-3'
                  onClick={() => {
                    loginUser(props.row.original)
                  }}
                >
                  Act as
                </a>
              </div>

              <div className='menu-item px-3'>
                
                <EnrollAction opportunityId={props.row.original.opp_id} isFull={isFull}/>
              </div>
              <div className='menu-item px-3'>
                <DeclineAction opportunityId={props.row.original.opp_id}/>
              </div>
            </div>
          </>
          )
        }
      },
    ],
    []
  )

  return (
    <>
      {successDelete.data && (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{successDelete.message}</span>
            </div>
          </div>
        </div>
      )}

      {errorMessage && (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{errorMessage}</span>
            </div>
          </div>
        </div>
      )}
      {progressLoading ? (
        <div className='card-body'>
          <div className='alert alert-warning d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span className='fw-bold'>Export is in progress</span>
            </div>
          </div>
        </div>
      ) : (progressData && progressData?.status === 200) ? (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span className='fw-bold'>{progressData?.data?.message}</span>
            </div>
          </div>
        </div>
      ) : (progressData && progressData?.status !== 200) ? (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span className='fw-bold'>{progressData?.data?.message}</span>
            </div>
          </div>
        </div>
      ) : ''}

     

      <div className='card card-body p-0'>

        <div className='d-flex align-items-center justify-content-between pe-9'>
          <Filter 
            setSearch={setSearch}
            search={search} />
          <div className='mt-5'>
            <button 
              className='btn btn-primary d-flex align-items-center' 
              onClick={getExportProgress} 
              disabled={progressLoading}
            >
              {progressLoading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                <>
                  <PiExport size={20} className='me-2' color='white' />
                  Export Progress
                </>
              )}
            </button>
          </div>
        </div>
       
        <DynamicTableV2 columns={columns} data={visibleData} loading={loading} />
      </div>
    </>
  )
}
export default Waitlist
