import { Field, Form, Formik, FormikValues } from 'formik'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import CustomInput from '../../../../../_metronic/shared-components/CustomInput'
import usePaymentInput from '../ConfirmPay/useInputPayments'
import * as Yup from 'yup'
import { axiosPrivate } from '../../../../../axiosInstance'

const EditAddonCharges = ({item, refetch}: any) => {
  const [paymentAmount, handleInputChange, resetPaymentAmount] = usePaymentInput()
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const initialValues = {
    name: item.product_name || '',
    price: item.price || ''
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Add-on name is required'),
    price: Yup.string(),
  });

  const handleClose = () => {
    setShowModal(prev => !prev)
    setLoading(false)
    setSuccessMessage('')
    setErrorMessage('')
  }

  const onSubmit = async (values: any) => {
    setLoading(true)
    try {
      const UpdatedValues = {
        opportunity_detail_id: item.id,
        sf_id: item.sf_id,
        product_name: values.name,
        opportunity_id: item.opportunity_id,
        price: values.price,
        old_price: item.price
      }
      const response = await axiosPrivate.post(`/edit_addon`, UpdatedValues)
      if (response.status === 201) {
        setSuccessMessage(response.data.message)
      }
      setTimeout(() => {
        handleClose()
      }, 3000)
    } catch (error: any) {
      setLoading(false)
      setErrorMessage(error.response.data.message)
      setTimeout(() => {
        setErrorMessage('')
      }, 3000)
    } finally {
      refetch()
    }
  }

  useEffect(() => {
    handleInputChange(item.price)
  }, [])

  return (
    <>
      <span className='text-primary cursor-pointer text-hover' onClick={handleClose}>Edit</span>
        <Formik 
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
        {({ values, setFieldValue, errors }) => {
          return (
            <Modal show={showModal} onHide={handleClose} backdrop='static' keyboard={false} centered size='lg'>
              <Form>
                <Modal.Body>
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Scholarship</Modal.Title>
                  </Modal.Header>
                  <div className='mt-5'>
                    <CustomInput 
                      label="Add-on Name"
                      name='name'
                      value={values.name}
                      maxLength={100}
                    />
                  </div>
                  <div>
                    <Field
                      type='text'
                      name='price'
                      value={paymentAmount || "0"}
                      className='form-control'
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleInputChange(e.target.value)
                        setFieldValue('price', e.target.value)
                      }}
                      maxLength={12}
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer style={{ padding: '20px 30px' }}>
                  <Button variant='secondary' onClick={handleClose}>
                    Discard
                  </Button>
                  <Button
                    variant='info'
                    className='ms-2'
                    type='submit'
                  >
                    {loading ? (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    ) : 'Update'}
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          )
        }}
      </Formik>
    </>
  )
}

export default EditAddonCharges