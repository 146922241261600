import { useState } from 'react'
import {useAuth} from '../../../../app/modules/auth'
import { useNavigate, useParams } from 'react-router-dom'
import { ICouponCode} from './model'
import { Form, Formik, FormikValues } from 'formik'
import { addOrEditCouponCode} from '../../../../network/api'
import { CouponCodeSchema } from './Schemas/CouponCodeSchema'
import CustomInput from '../../../../_metronic/shared-components/CustomInput'
import CustomTextArea from '../../../../_metronic/shared-components/CustomTextArea'
import CustomReactSelect from '../../../../_metronic/shared-components/CustomReactSelect'
import CustomCheckbox from '../../../../_metronic/shared-components/CustomCheckbox'
import { codeMessage } from '../../../../network/codeMessage'

const CouponCodeAdd = () => {

  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const navigate = useNavigate()
  const { client } = useParams()
  const { currentUser } = useAuth()

  const initialValues = {
    code_number: '',
    status: '',
  }

  const onSubmit = async (values: ICouponCode, actions: FormikValues) => {
    try {
      const modifiedValues = {
        ...values,
        created_by: Number(currentUser?.id),
        code_number: String(values.code_number),
        status: values.status ? 'active' : 'inactive',
      }
      const res = await addOrEditCouponCode(modifiedValues)
      const Success = codeMessage[res.status]
      setSuccessMessage(Success)
  
      actions.resetForm()
  
      setTimeout(() => {
        navigate(`/apps/${client}/coupon-code-management/coupon-code-list`)
      }, 3000)
    } catch (error: any) {
      const errorMsg = codeMessage[error.response.status]
      setErrorMessage(errorMsg)

      setTimeout(() => {
        setErrorMessage('')
      }, 3000)
    }
  }

  
  return (
    <>
    {successMessage && (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{successMessage}</span>
            </div>
          </div>
        </div>
      )}

    {errorMessage && (
      <div className='card-body'>
        <div className='alert alert-danger d-flex align-items-center p-5'>
          <div className='d-flex flex-column '>
            <span>{errorMessage}</span>
          </div>
        </div>
      </div>
    )}

    <div className='card mb-5 mb-xl-8'>
      <div className='card-body'>
        <div className='card'>
          <Formik
            initialValues={initialValues}
            validationSchema={CouponCodeSchema}
            onSubmit={onSubmit}
          >
            {({isSubmitting, setFieldValue}) => {
              return (
                <Form>
                  <CustomInput label='Code Name' name='code_number' placeholder='Enter a Code name' />

                  <CustomCheckbox label='Status' type='checkbox' name='status' />

                  <div className='text-center pt-15'>
                    <button
                      disabled={isSubmitting}
                      type='submit'
                      className='btn btn-primary cursor-pointer'
                      data-kt-users-modal-action='submit'
                    >
                      <span className='indicator-label'>Submit</span>
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
    </>
  )
}

export default CouponCodeAdd