import {FC, useState, createContext, useContext, useMemo} from 'react'
import {
  ID,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import {useQueryResponse, useQueryResponseData} from './QueryResponseProvider'
import {initialListView, ClientsListViewContextProps} from './_models'

const ListViewContext = createContext<ClientsListViewContextProps>(initialListView)

const ClientsViewProvider: FC<WithChildren> = ({children}) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const [itemIdForDelete, setItemIdForDelete] = useState<{id: ID} | undefined>(
    initialListView.itemIdForDelete
  )
  const {isLoading} = useQueryResponse()
  const data = useQueryResponseData()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])

  const contextValue = useMemo(() => ({
    selected,
    disabled,
    isAllSelected,
    itemIdForDelete,
    setItemIdForDelete,
    onSelect: (id: ID) => {
      groupingOnSelect(id, selected, setSelected)
    },
    onSelectAll: () => {
      groupingOnSelectAll(isAllSelected, setSelected, data)
    },
    clearSelected: () => {
      setSelected([])
    }
  }), [selected, disabled, isAllSelected, itemIdForDelete, data])

  return (
    <ListViewContext.Provider value={contextValue}>
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ClientsViewProvider, useListView}
