import { ID } from '../../../../_metronic/helpers'
import * as Yup from 'yup'
import { generate } from '@wcj/generate-password'
import { baseTextYup, emailYup, phoneRegExp } from '../../Shared/core/_models'
import { iFlight } from '../../../pages/dashboard/FlightPlan'


const GLA_FEE = 95

// UPDATED ENROLLMENT DESIGN
export interface IProducts {
  programs: IProduct[]
}
export interface IProduct {
  name: string
  detail: IProductDetail[]
}
export interface IProductDetail {
  id: number
  name: string
  division?: string
  country: string
  startDate: string
  endDate: string
  duration: number
  tuition: number
  deposit: number
  status: string | null
  type: string
  extras: IEnrollmentExtra[]
  group: string
  program_track: IAcademicTrack[]
}

export interface IOpportunityExtra {
  id: number
  opportunity_id: number
  extra_id: number
  is_required?: boolean
  program_product_extra: IEnrollmentExtra
}
export interface IAcademicTrack {
  id: number
  track_id: number
  name: string
  capacity: string
  required_extra_id: number | null
  status: string
}
// UPDATED ENROLLMENT DESIGN
export interface IEnrollment {
  id: number,
  name: string,
  student: string,
  client_id: string,
  opp_status: IStatus,
  remaining_balance: string,
  product_list: IEnrollmentObject[],
  schedules?: IPaymentSchedule[],
  payment_schedule?: IPaymentSchedule,
  fundings?: IPaymentMethod[],
  extras?: IOpportunityExtra[],
  scholarship?: any,
  opportunity_detail?: any[],
  payment?: any[],
  sync_product_code: any
  scholarship_list?: any[]
}
export interface IPortalClients {
  portalClient: IPortalClient[]
}

export interface IPortalClient {
  value: string,
  name: string,
  abbreviation: string,
}


export interface IProgramEnroll {
  id: number
  name: string
  region: string
  country: string
  program_summary: string
  type: string
  programDates: IProgramDate[],
}

export interface IGeneralFee {
  id: number
  name: string
  category: number
  type: string
  amount: number
  created_at: string
  updated_at: string
}

export interface ICheckout {
  program_id: number
  additional_option: number[]
}
export interface ProductList {
  name?: string;
  uniqueName?: string;
  startDate?: string;
  endDate?: string;
  type?: string;
}
export interface IProgram {
  id?: ID
  loginuser_id?: number
  productgroup?: number  // We will no longer use program.
  ssbproductgroup?: ProductList
  product?: number  // We will no longer use program.
  ssb_product?: number
  academicTrack?: string
  program?: string
  programDate?: string[]
  studentAccount?: IStudentAccount | null
  contactInfo?: IContactInfo | null
  acceptTerms?: boolean
  isSponsored?: boolean
  scholarshipCode?: string | null
  fee?: number
  client_id?: string
  student_type?: string
  checkout?: ICheckout[]
  additionalOpt?: string[]
  scholarship?: any
  isLimit?: boolean
}

export interface IEnrollmentExtra {
  id: number
  program_product_id: number
  enrollment_extra_id: number
  is_required: boolean | null
  created_at: string
  updated_at: string
  enrollment_extra: IEnrollmentExtraDetail
  category?: number
}

export interface IEnrollmentExtraDetail {
  id: number
  name: string
  type: string
  category: number
  amount: number
  created_at: string
  updated_at: string
}
export interface IProgramDate {
  id: number
  ref_id: number
  startDate: Date
  endDate: Date
  duration: number
  deposit: number
  tuition: number
  status: string
  type?: string
  session?: IProgramSession[]
  programInfo?: {
    id: number
    name: string
    region: string
    country: string
    program_summary: string
    type: string
  }
  enrollment_extras?: IEnrollmentExtra[]
  fees?: IEnrollmentExtra[]
}

export interface IProgramSession {
  id: number
  active_days?: string
  capacity?: string
  end_date: string
  start_date: string
  year: string
  name: string
  is_active: boolean
  status: string
  track: IAcademicTrack[]
  max_slots: number
}

export interface IProgramTrack {
  id: number
  name: string
  program_product_id: number
  program_session_id: number
  max_slots: number
  is_active: boolean
  program_session: IAcademicTrack[]
}
export interface IEnrollmentObject {
  id: number
  name: string
  start_date: string
  end_date: string
  country: string
  tuition: number
  program_summary: string
  duration: number
  due_upon_application: number
  status?: string
  division: string
  student_type?: string
  session?: IProgramTrack[]
  extras?: IEnrollmentExtra[]
  sf_id: string | null
}

export interface IPaymentSchedule {
  id?: number
  name: string
  type: string
  due_dates: string[]
  status_id?: number
  client_id?: string
  status?: IStatus
  client?: IPortalClient
  due_date?: Date | string
  value?: string
}
export interface IStudentAccount {
  email: string
  password: string
  confirmPassword: string
}

export interface IBaseInfo {
  firstName: string
  lastName: string
  email: string
  country: string
  phoneNumber: string
}

export interface IStudentInfo extends IBaseInfo {
  birthday: Date | string
  tshirtSize: string
  schoolAttending: string
  nickname: string
  gender: string
  genderDescription: string
  sexAssignedAtBirth: string
  pronouns: string
  pronounsDescription: string
}

export interface IParentInfo extends IBaseInfo { }
export interface ISecondParentInfo extends IBaseInfo { }

export interface IAdvisorInfo {
  firstName: string
  lastName: string
  email: string
}

export interface IBaseAddress {
  address: string
  city: string
  stateProvince: string
  postalCode: string
  country: string
}

export interface IStudentAddress extends IBaseAddress { }
export interface IParentAddress extends IBaseAddress { }

export interface IContactInfo {
  studentInfo: IStudentInfo
  parentInfo: IParentInfo
  secondParentInfo: ISecondParentInfo | null
  advisorInfo: IAdvisorInfo | null
  studentAddress: IStudentAddress
  parentAddress: IParentAddress | null
}

export interface ICreditCard {
  nameOnCard: string
}

export interface ICreditCard {
  nameOnCard: string
  cardNumber: string
  cardExpiryMonth: string
  cardExpiryYear: string
  cardCvv: string
  saveCard: string
  billingAddress: string
  billingCity: string
  billingStateProvince: string
  billingPostalCode: string
  billingCountry: string
}
export interface IBankAccount {
  nameOnCard: string
  routingNumber: string
  accountNumber: string
  bankType: string
}

export interface ISponsoredPayment {
  sponsorCode: string
}

export interface IPayOpt {
  paymentMethod: string
  creditCardInfo: ICreditCard
  bankAccountInfo: IBankAccount
  sponsoredInfo: ISponsoredPayment
  parentSignatureTerms: string
  opportunity: number | undefined
  amount: number
  payment_schedule: string
  totalAmount: number
  selectPMethod: string | undefined
  comment: string | undefined
  firstPay?: boolean
  program_protection_plan?: boolean
  protection_price?: number,
  protection_product_id?: number | undefined
  protection_product_name?: string | undefined,
  protection_product_sf_id?: string | undefined,
  protection_product_pricebook?: string | undefined,
  login_id?: number,
  opportunity_sf_id?: string | undefined
  isLimit? : boolean | false
  isCustomPay?: boolean | false
}

export interface IProtectionPlan {
  totalAmount: string
  opportunity: number
  login_id: number
  opportunity_sf_id: string
}

const genPassword = generate({
  length: 10,
})

export const T_SHIRT_SIZE = [
  {
    value: 'xs',
    adult: 'Adult Extra Small',
  },
  {
    value: 'sm',
    adult: 'Adult Small',
  },
  {
    value: 'md',
    adult: 'Adult Medium',
  },
  {
    value: 'lg',
    adult: 'Adult Large',
  },
  {
    value: 'xl',
    adult: 'Adult Extra large',
  },
  {
    value: 'xxl',
    adult: 'Adult Double Extra large',
  }
]

export const GRADE_ENTERING = [
  {
    value: '7th',
    label: '7th'
  },
  {
    value: '8th',
    label: '8th'
  },
  {
    value: '9th',
    label: '9th'
  },
  {
    value: '10th',
    label: '10th'
  },
  {
    value: '11th',
    label: '11th'
  },
  {
    value: '12th',
    label: '12th'
  },
  {
    value: 'HS Grad',
    label: 'HS Grad'
  },
]

export const initsPayOpt: IPayOpt = {
  paymentMethod: 'bankAccount',
  creditCardInfo: {
    nameOnCard: '',
    cardNumber: '',
    cardExpiryMonth: '',
    cardExpiryYear: '',
    cardCvv: '',
    saveCard: '1',
    billingAddress: '',
    billingCity: '',
    billingStateProvince: '',
    billingPostalCode: '',
    billingCountry: '',
  },
  bankAccountInfo: {
    nameOnCard: '',
    routingNumber: '',
    accountNumber: '',
    bankType: 'checkings',
  },
  sponsoredInfo: {
    sponsorCode: '',
  },
  parentSignatureTerms: '',
  opportunity: undefined,
  amount: 0,
  payment_schedule: '',
  totalAmount: 0,
  selectPMethod: undefined,
  comment: '',
}

export const glaPaymentSchemas = Yup.object({
  paymentMethod: Yup.string()
    .oneOf(['creditCard', 'bankAccount', 'sponsored'])
    .required('Please choose an payment method'),
  creditCardInfo: Yup.object({
    nameOnCard: Yup.string().label('Name On Card'),
    cardNumber: Yup.string().label('Card Number'),
    cardExpiryMonth: Yup.string().label('Expiration Month'),
    cardExpiryYear: Yup.string().label('Expiration Year'),
    cardCvv: Yup.string().label('CVV'),
    billingAddress: Yup.string(),
    billingCity: Yup.string(),
    billingStateProvince: Yup.string(),
    billingPostalCode: Yup.string(),
    billingCountry: Yup.string(),
  }),
  amount: Yup.number(),
  sponsoredInfo: Yup.object({
    sponsorCode: Yup.string(),
  }),
  parentSignatureTerms: Yup.string().required('Parent signature terms is required'),
  payment_schedule: Yup.string(),
})

export const glaPaymentSchemasAddFund = Yup.object({
  paymentMethod: Yup.string()
    .oneOf(['creditCard', 'bankAccount', 'sponsored'])
    .required('Please choose an payment method'),
  creditCardInfo: Yup.object({
    nameOnCard: Yup.string().label('Name On Card'),
    cardNumber: Yup.string().label('Card Number'),
    cardExpiryMonth: Yup.string().label('Expiration Month'),
    cardExpiryYear: Yup.string().label('Expiration Year'),
    cardCvv: Yup.string().label('CVV'),
    billingAddress: Yup.string(),
    billingCity: Yup.string(),
    billingStateProvince: Yup.string(),
    billingPostalCode: Yup.string(),
    billingCountry: Yup.string(),
  }),
  sponsoredInfo: Yup.object({
    sponsorCode: Yup.string(),
  }),
})
export const initsProgram: IProgram = {
  program: '',
  productgroup: 0,
  product: -1,
  programDate: [],
  isLimit: false,
  studentAccount: {
    email: '',
    password: '',
    confirmPassword: '',
  },
  contactInfo: {
    studentInfo: {
      firstName: '',
      lastName: '',
      email: '',
      country: 'USA',
      phoneNumber: '',
      birthday: '',
      tshirtSize: '',
      schoolAttending: '',
      nickname: '',
      gender: '',
      genderDescription: '',
      sexAssignedAtBirth: '',
      pronouns: '',
      pronounsDescription: '',
    },
    parentInfo: {
      firstName: '',
      lastName: '',
      email: '',
      country: 'USA',
      phoneNumber: '',
    },
    secondParentInfo: {
      firstName: '',
      lastName: '',
      email: '',
      country: '',
      phoneNumber: '',
    },
    advisorInfo: {
      firstName: '',
      lastName: '',
      email: '',
    },
    studentAddress: {
      address: '',
      city: '',
      stateProvince: '',
      postalCode: '',
      country: '',
    },
    parentAddress: {
      address: '',
      city: '',
      stateProvince: '',
      postalCode: '',
      country: '',
    },
  },
  acceptTerms: false,
  isSponsored: false,
  scholarshipCode: '',
  fee: GLA_FEE,
  client_id: '',
  student_type: '',
  checkout: [],
  additionalOpt: [],
  academicTrack: '',
}

export const glaEnrollSchemas = [
  //step1
  Yup.object({
    product: Yup.number().moreThan(0, 'Number must be greater than zero').required(),
  }),
  //step2
  Yup.object({
    programDate: Yup.array().of(Yup.string()).min(1, 'Please choose a program date'),
  }),
  //step3
  Yup.object({
    studentAccount: Yup.object().shape({
      email: emailYup,
      password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
      confirmPassword: Yup.string()
        .required('Password confirmation is required')
        .when('password', {
          is: (val: string) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref('password')],
            "Password and Confirm Password didn't match"
          ),
        }),
    }),
  }),
  //step 4
  Yup.object({
    contactInfo: Yup.object().shape({
      studentInfo: Yup.object({
        firstName: baseTextYup.required('First name is required'),
        lastName: baseTextYup.required('Last name is required'),
        tshirtSize: Yup.string().min(2, 'Minimum 2 symbols').max(3, 'Maximum 3 symbols'),
        schoolAttending: baseTextYup.required('Name of school attending is required'),
        nickname: baseTextYup,
        country: Yup.string().label('Country').required('Country is required'),
        phoneNumber: Yup.string()
          .matches(phoneRegExp, 'Phone number is not valid')
          .required('Phone number is required'),
        birthday: Yup.date().label('Birthday').required('Birthday is required'),
      }),
      parentInfo: Yup.object({
        firstName: baseTextYup.required('First name is required'),
        lastName: baseTextYup.required('Last name is required'),
        email: emailYup.required('Email is required'),
        country: Yup.string().required('Country is required'),
        gender: Yup.string().label('Gender').required('Gender is required'),
        sexAssignedAtBirth: Yup.string().label('Sex Assigned At Birth').required('Sex Assigned At Birth is required'),
        pronouns: Yup.string().label('Pronouns').required('Pronouns is required'),
        phoneNumber: Yup.string()
          .matches(phoneRegExp, 'Phone number is not valid')
          .required('Phone number is required'),
      }),
      secondParentInfo: Yup.object({
        firstName: baseTextYup,
        lastName: baseTextYup,
        email: emailYup,
        country: Yup.string(),
        phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
      }).nullable(),
      studentAddress: Yup.object({
        address: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        stateProvince: Yup.string().required('Required'),
        postalCode: Yup.string().required('Required'),
        country: Yup.string().required('Required'),
      }),
      parentAddress: Yup.object({
        address: Yup.string(),
        city: Yup.string(),
        stateProvince: Yup.string(),
        postalCode: Yup.string(),
        country: Yup.string(),
      }),
    }),
  }),
  //step 5
  Yup.object({
    acceptTerms: Yup.bool()
      .isTrue(`You must acknowledge and agree to GLA's Privacy Policy to reserve your spot.`)
      .required(`You must acknowledge and agree to GLA's Privacy Policy to reserve your spot.`),
  }),
]

export interface ISelfReflection {
  Current_GPA__c: string
  Extracurricular_activities__c: string
  Essay_Question_1__c: string
  Essay_Question_2__c: string
  How_Family_First_Heard__c: string
  How_Family_First_Heard_Description__c: string
}

export interface ISelfAssessment {
  Self_Assessment_1__c: string
  Self_Assessment_2__c: string
  Self_Assessment_3__c: string
  Self_Assessment_4__c: string
  Self_Assessment_5__c: string
  Self_Assessment_6__c: string
  Self_Assessment_7__c: string
  Self_Assessment_8__c: string
  Self_Assessment_9__c: string
}

export interface IMedicalForm {
  parent_signature_medical: string
  Med_Height__c: string
  Med_Weight__c: string
  Gender: string
  Gender_Description__c: string
  Sex_Assigned_At_Birth__c: string
  Pronouns: string
  Ethnicity__c: string[]
}
export interface ICodeOfConduct {
  student_signature_coc: string
  parent_signature_coc: string
}
export interface IEnrollPartTwo {
  insurance: boolean
  self_reflection: ISelfReflection | null
  self_assessment: ISelfAssessment | null
  medical_form: IMedicalForm
  opportunity: number | undefined
  code_of_conduct: ICodeOfConduct
  isComplete?: boolean
}

export const SELF_REFLECTION_FORM = [
  {
    id: 1,
    name: 'Current_GPA__c',
    type: 'question',
    field_type: 'text',
    label: `Current grade point average or your school's equivalent`,
    required: false,
    isValidated: false,
  },
  {
    id: 2,
    name: 'Extracurricular_activities__c',
    type: 'question',
    field_type: 'textarea',
    label: `Self-Reflection Question 1`,
    note: `Share a goal that you have for yourself and how you think your GLA experience may contribute to achieving that goal. (Provide 200- 300 word reflection)`,
    required: false,
    isValidated: true,
  },
  {
    id: 3,
    name: 'Essay_Question_1__c',
    type: 'question',
    field_type: 'textarea',
    label: `Self-Reflection Question 2`,
    note: `At GLA we believe "Leaders are made, not born." What skills do you think you need to develop in order to become a more effective leader? How do you envision your GLA experience helping you grow as a leader? (Provide 200- 300 word reflection)`,
    required: false,
    isValidated: true,
  },
  {
    id: 4,
    name: 'Essay_Question_2__c',
    type: 'question',
    field_type: 'textarea',
    label: `Self-Reflection Question 2`,
    note: `At GLA we believe "Leaders are made, not born." What skills do you think you need to develop in order to become a more effective leader? How do you envision your GLA experience helping you grow as a leader? (Provide 200- 300 word reflection)`,
    required: false,
    isValidated: true,
  },
  {
    id: 5,
    name: 'How_Family_First_Heard__c',
    type: 'question',
    field_type: 'select',
    label: `How did your family first hear about Global Leadership Adventures?`,
    choices: [
      '---- Select Answer ----',
      'Word of mouth (a friend, sibling, parent, etc)',
      'Google',
      'Bing',
      'Facebook',
      'Email',
      'Event (fair, meet & greet, open house)',
      'At school',
      'GLA Catalog',
      'Video',
      'Other',
    ],
    required: false,
    isValidated: true,
  },
]

export const SELF_ASSESSMENT_FORM = [
  {
    id: 1,
    name: 'Self_Assessment_1__c',
    type: 'question',
    field_type: 'select',
    label: `Which of these best describes your relationship to GLA?`,
    choices: [
      '---- Select Answer ----',
      `This is primarily my parents' idea, but I'm going along with an open mind.`,
      `This is my idea and I struggled to convince my parents.`,
      `I'm applying mainly because a friend or family member is going.`,
      `I'm applying to GLA because another program didn't work out.`,
    ],
    required: false,
    isValidated: true,
  },
  {
    id: 2,
    name: 'Self_Assessment_2__c',
    type: 'question',
    field_type: 'select',
    label: `What is your primary motivation for participating in GLA? (We know you may have many, what is the top of your list)?`,
    choices: [
      '---- Select Answer ----',
      `This is primarily my parents' idea, but I'm going along with an open mind.`,
      `This is my idea and I struggled to convince my parents.`,
      `I'm applying mainly because a friend or family member is going.`,
      `I'm applying to GLA because another program didn't work out.`,
    ],
    required: false,
    isValidated: true,
  },
  {
    id: 3,
    name: 'Self_Assessment_3__c',
    type: 'question',
    field_type: 'select',
    label: `Which best describes you?`,
    choices: [
      '---- Select Answer ----',
      `This is primarily my parents' idea, but I'm going along with an open mind.`,
      `This is my idea and I struggled to convince my parents.`,
      `I'm applying mainly because a friend or family member is going.`,
      `I'm applying to GLA because another program didn't work out.`,
    ],
    required: false,
    isValidated: true,
  },
  {
    id: 4,
    name: 'Self_Assessment_4__c',
    type: 'question',
    field_type: 'select',
    label: `Which best describes you?`,
    choices: [
      '---- Select Answer ----',
      `This is primarily my parents' idea, but I'm going along with an open mind.`,
      `This is my idea and I struggled to convince my parents.`,
      `I'm applying mainly because a friend or family member is going.`,
      `I'm applying to GLA because another program didn't work out.`,
    ],
    required: false,
    isValidated: true,
  },
]

export interface ICompletionStatusData {
  insurance: boolean
  self_reflection: boolean
  self_assessment: boolean
  medical_form: boolean
}

export interface IPartTwoStatus {
  insurance_complete: boolean
  self_reflection_complete: boolean
  self_assessment_complete: boolean
  medical_form_complete: boolean
  code_conduct_complete: boolean
}

export interface IEnrollmentStatus {
  part_two: IPartTwoStatus
  is_confirm_and_payment_submitted: boolean
  application_status: string
  is_application_submitted: boolean
  is_part_two_completed: boolean
  emergency_contact_status: string
  preprogram_questionnaire_status: string
}

export interface IEnrollee {
  id: number
  firstName: string
  lastName: string
  email: string
  status: string
  client_id: string
  sf_id: string
  enrolleeAddress: IStudentAddress
}

export interface IPredepartureItem {
  id: number
  title: string
  name: string
  type: string
  download_file_url: string
  route_name: string
  client_id: string
  status: string
  header: string
  note: string
  instruction: string
  description: string
  created_at?: string
  task_overview?: string
  task_deadline?: string
  custom_input?: string
  task_type?: string
}

export interface IDocumentFile {
  id: number
  path: string
  original_name: string
  mimetype: string
  type: string
  opportunity_id: number
  loginuser_id: number
  created_at: string
  updated_at: string
  view?: string
}
export interface IEnrollmentSummary {
  opportunity: number
  name: String
  email: String
  enrollment_objects: IEnrollmentObject[]
  predeparture_items: IPredepartureItem[] | null
  status: number
  opp_status: IStatus
  sessions?: IProgramSession
  remaining_balance: number | null
  payment_schedule: IPaymentSchedule | null
  extras: IOpportunityExtra[]
  payment_methods?: IPaymentMethod[]
  scholarship: any
  detail: any[]
  payment: any[]
  sf_product_syncing?: ISFProduct[]
  scholarship_list?: any[]
  editInProgress?: boolean
  ppp?: boolean
  login_user?: number
  opp_sf_id?: string
  isFull: boolean
}

export interface ITuitionPlan {
  totalAmount?: number
  opp_id?: number
  login_id?: number
  opportunity_sf_id?: string
}

export interface ISFProduct {
  sf_product_name: string
  sf_product_total_price: number
  sf_type: string
}

export interface IStatus {
  id: number
  name: string,
  type: string
}
export interface IFlightData {
  country: string,
  flights: iFlight[]
  opportunities: IOpportunity[],
  product_name: string,
}
export interface IOpportunity {
  id: number,
  name: string,
  account: number,
  due_upon_application: null,
  duration: null,
  end_date: string,
  program_summary: null,
  status: string,
  start_date: string,
  country: null,
  division: null,
  tuition: null,
  pricebook_entry_id: null,
  is_guaranteed: null,
  slug: null,
  group: null,
  products: string,
  is_sponsored: null,
  insurance_complete: null,
  self_reflection_complete: null,
  self_assessment_complete: null,
  medical_form_complete: null,
  code_conduct_complete: null,
  emergency_contact_status: string,
  preprogram_questionnaire_status: string,
  payment_schedule_id: null,
  remaining_balance: null,
  customer_id: null,
  client_id: string,
  student: any,
}

export interface IFlight {
  program_summary: string,
  id: number,
}
export interface IPaymentMethod {
  id: string
  orbipay_id: string | undefined | null
  paymentType: string
  subType?: string | undefined | null
  cardNumber?: string | undefined | null
  cardType?: string | undefined | null
  routingNumber?: string | undefined | null
  accountNumber?: string | undefined | null
  expireDates: string | undefined | null
  isActive: boolean
  address: IBaseAddress,
  account_name: string | undefined | null
}
export interface IPaymentData {
  programId?: string
  programDate?: string[]
  opportunity?: number | undefined
}
export interface IPaymentMethods {
  user: number
  email: string
  payment_methods: IPaymentMethod[]
}

export const initialPayment: PaymentMethod = {
  id: '',
  paymentType: '',
  isActive: false,
  subType: undefined,
  creditCardInfo: {
    nameOnCard: '',
    cardNumber: '',
    cardExpiryMonth: '1',
    cardExpiryYear: '2022',
    cardCvv: '',
    saveCard: '',
    billingAddress: '',
    billingCity: '',
    billingStateProvince: '',
    billingPostalCode: '',
    billingCountry: '',
  },
  bankAccountInfo: {
    nameOnCard: '',
    routingNumber: '',
    accountNumber: '',
    bankType: '',
  },
}

export type PaymentMethod = {
  id?: string
  paymentType?: string
  subType?: string
  isActive?: boolean
  paymentMethod?: string | undefined | null
  creditCardInfo?: ICreditCard | undefined | null
  bankAccountInfo?: IBankAccount | undefined | null
}

export type TPaymentMethodResponse = {
  id?: string
  paymentType?: string
  subType?: string
  cardNumber?: string | undefined | null
  cardType?: string | undefined | null
  routingNumber?: string | undefined | null
  accountNumber?: string | undefined | null
  expireDates?: Date | string | undefined | null
  isActive?: boolean
}
export interface ICurrentStepObject {
  [x: string]: any
  opportunity: number | undefined
}

export const initPolicies: any = {
  opportunity: undefined,
  customField: {

  }
}

const MedicalObject = {
  parent_signature_medical: '',
  Med_Height__c: '',
  Med_Weight__c: '',
  Gender: '',
  Sex_Assigned_At_Birth__c: '',
  Gender_Description__c: '',
  Pronouns: '',
  Ethnicity__c: [],
  Pronouns_Description__c: '',
  Self_Identify__c: '',
  Taking_Prescription__c: '',
  Taking_Prescription_Description__c: '',
  Mark_Details__c: [],
  Food_Allergy_Description__c: '',
  Food_Intolerance_Description__c: '',
  Vegan_Diet_Description__c: '',
  Other_Dietary_Description__c: '',
  allergies: '',
  Allergies: '',
  Med_Allergies_drug__c: '',
  Med_Allergies_drug_Description__c: '',
  Med_Allergies_EpiPen__c: '',
  Med_Allergies_EpiPen_Description__c: '',
  Med_Allergies_EpiPen_Last_Use__c: '',
  Med_Asthma__c: '',
  Med_Asthma_Description__c: '',
  Med_Asthma_Inhaler__c: '',
  Med_Asthma_Inhaler_Description__c: '',
  Med_Heart_Problems__c: '',
  Med_Heart_Problems_Description__c: '',
  Med_Fainting_or_Dizziness_Last_Episode__c: '',
  Med_Diabetes__c: '',
  Med_Diabetes_Description__c: '',
  Med_Headache__c: '',
  Med_Headache_Description__c: '',
  Med_Seizures__c: '',
  Med_Seizures_Description__c: '',
  Med_Seizures_Last__c: '',
  Med_Broken_Bones__c: '',
  Med_Broken_Bones_Description__c: '',
  Med_Concussion__c: '',
  Med_Concussion_Description__c: '',
  Med_Surgeries__c: '',
  Med_Surgeries_Description__c: '',
  Med_Glasses__c: '',
  Med_Sleepwalking__c: '',
  Med_Sleepwalking_Description__c: '',
  Med_Fear_Swimming__c: '',
  Med_Fear_Swimming_Description__c: '',
  Med_Fear_Swimming_Choices__c: '',
  Med_ADHD_ADD__c: '',
  Med_ADHD_ADD_Description__c: '',
  Med_Diagnosed_with_Depression__c: '',
  Med_Diagnosed_with_Depression_Descript__c: '',
  Med_Depression_Last_Episode__c: '',
  Med_Anxiety__c: '',
  Med_Anxiety_Description__c: '',
  Med_Anxiety_Last_Episode__c: '',
  Med_Bi_Polar_Disorder__c: '',
  Med_Positive_For_COVID_19_Date__c: '',
  Med_Bi_Polar_Disorder_Description__c: '',
  Med_Bi_Polar_Last_Episode__c: '',
  Med_Self_Mutilation__c: '',
  Med_Self_Mutilation_Description__c: '',
  Med_Self_Mutilation_Last_Episode__c: '',
  Eating_Disorder__c: '',
  Eating_Disorder_Description__c: '',
  Med_Eating_Disorder_Last__c: '',
  Med_Panic_Attacks__c: '',
  Med_Panic_Attacks_Description__c: '',
  Med_Panic_Attacks_Frequency__c: '',
  Med_Suicidal_Thoughts__c: '',
  Med_Suicidal_Thoughts_Description__c: '',
  Med_Suicidal_Thoughts_Last__c: '',
  Med_Suicide_Attempt__c: '',
  Med_Last_Suicide_Attempt__c: '',
  Med_Alcohol_Drugs__c: '',
  Med_Alcohol_Drugs_Description__c: '',
  Med_Autism_Spectrum__c: '',
  Med_Autism_Spectrum_Description__c: '',
  Med_Missing_Routine_Vaccinations__c: '',
  Med_Missing_Routine_Vaccinations_Descrip__c: '',
  Med_Self_Advocacy_Concerns__c: '',
  Med_Self_Advocacy_Concerns_Description__c: '',
  Med_Suspended_Or_Expelled__c: '',
  Med_Suspended_Or_Expelled_Description__c: '',
  Med_Positive_For_COVID_19__c: '',
  Med_Covid_19_Fully_Vaccinated__c: '',
  Med_Covid_19_Vaccine_Type__c: '',
  Med_Covid_19_Recent_Booster_Shot__c: '',
  Med_Covid_19_Vaccine_Appointment_Date__c: '',
  Med_Other__c: '',
  Med_Other_Description__c: '',
}

export const partTwoSchemas = [
  Yup.object({ insurance: Yup.bool() }),
  //step2
  Yup.object({
    self_reflection: Yup.object({
      Current_GPA__c: baseTextYup.nullable().label('Current GPA'),
      Extracurricular_activities__c: Yup.string()
        .min(200, 'Minimum 200 words')
        .max(300, 'Maximum 300 words')
        .required('Required'),
      Essay_Question_1__c: Yup.string()
        .min(200, 'Minimum 200 words')
        .max(300, 'Maximum 300 words')
        .required('Required'),
      Essay_Question_2__c: Yup.string()
        .min(200, 'Minimum 200 words')
        .max(300, 'Maximum 300 words')
        .required('Required'),
      How_Family_First_Heard__c: Yup.string().required('Required'),
    }),
  }),
  //step3
  Yup.object({
    self_assessment: Yup.object({
      Self_Assessment_1__c: Yup.string().required('Required'),
      Self_Assessment_2__c: Yup.string().required('Required'),
      Self_Assessment_3__c: Yup.string().required('Required'),
      Self_Assessment_4__c: Yup.string().required('Required'),
      Self_Assessment_5__c: Yup.string().required('Required'),
      Self_Assessment_6__c: Yup.string().required('Required'),
      Self_Assessment_7__c: Yup.string().required('Required'),
      Self_Assessment_8__c: Yup.string().required('Required'),
      Self_Assessment_9__c: Yup.string().required('Required'),
    }),
  }),
  //step4
  Yup.object({
    medical_form: Yup.object({
      parent_signature_medical: Yup.string().required('Required'),
      Med_Height__c: Yup.string().required('Required'),
      Med_Weight__c: Yup.string().required('Required'),
      Gender: Yup.string().required('Required'),
      Sex_Assigned_At_Birth__c: Yup.string().required('Required'),
      Gender_Description__c: Yup.string(),
      Pronouns: Yup.string().required('Required'),
      Ethnicity__c: Yup.array().of(Yup.string()).min(1, 'Please choose an ethnicity'),
      Pronouns_Description__c: Yup.string(),
      Self_Identify__c: Yup.string(),
      Taking_Prescription__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Taking_Prescription_Description__c: Yup.string(),
      Mark_Details__c: Yup.array().of(Yup.string()).min(1, 'Please choose an option'),
      Food_Allergy_Description__c: Yup.string(),
      Food_Intolerance_Description__c: Yup.string(),
      Vegan_Diet_Description__c: Yup.string(),
      Other_Dietary_Description__c: Yup.string(),
      allergies: Yup.string().oneOf(['0', '1']).required('Please choose one of these options'),
      Allergies: Yup.string(),
      Med_Allergies_drug__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Allergies_drug_Description__c: Yup.string(),
      Med_Allergies_EpiPen__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Allergies_EpiPen_Description__c: Yup.string(),
      Med_Allergies_EpiPen_Last_Use__c: Yup.string(),
      Med_Asthma__c: Yup.string().oneOf(['0', '1']).required('Please choose one of these options'),
      Med_Asthma_Description__c: Yup.string(),
      Med_Asthma_Inhaler__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Asthma_Inhaler_Description__c: Yup.string(),
      Med_Heart_Problems__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Heart_Problems_Description__c: Yup.string(),
      Med_Fainting_or_Dizziness_Last_Episode__c: Yup.string(),
      Med_Diabetes__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Diabetes_Description__c: Yup.string(),
      Med_Headache__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Headache_Description__c: Yup.string(),
      Med_Seizures__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Seizures_Description__c: Yup.string(),
      //Med_Seizures_Last__c: '',
      Med_Broken_Bones__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Broken_Bones_Description__c: Yup.string(),
      Med_Concussion__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Concussion_Description__c: Yup.string(),
      Med_Surgeries__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Surgeries_Description__c: Yup.string(),
      Med_Glasses__c: Yup.string().oneOf(['0', '1']).required('Please choose one of these options'),
      Med_Sleepwalking__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Sleepwalking_Description__c: Yup.string(),
      Med_Fear_Swimming__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Fear_Swimming_Description__c: Yup.string(),
      Med_Fear_Swimming_Choices__c: Yup.string().required('Required'),
      Med_ADHD_ADD__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_ADHD_ADD_Description__c: Yup.string(),
      Med_Diagnosed_with_Depression__c: Yup.string(),
      Med_Diagnosed_with_Depression_Descript__c: Yup.string(),
      //Med_Depression_Last_Episode__c: '',
      Med_Anxiety__c: Yup.string().oneOf(['0', '1']).required('Please choose one of these options'),
      Med_Anxiety_Description__c: Yup.string(),
      //Med_Anxiety_Last_Episode__c: '',
      Med_Bi_Polar_Disorder__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Positive_For_COVID_19_Date__c: Yup.string(),
      Med_Bi_Polar_Disorder_Description__c: Yup.string(),
      //Med_Bi_Polar_Last_Episode__c: '',
      Med_Self_Mutilation__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Self_Mutilation_Description__c: Yup.string(),
      //Med_Self_Mutilation_Last_Episode__c: '',
      Eating_Disorder__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Eating_Disorder_Description__c: Yup.string(),
      //Med_Eating_Disorder_Last__c: '',
      Med_Panic_Attacks__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Panic_Attacks_Description__c: Yup.string(),
      //Med_Panic_Attacks_Frequency__c: '',
      Med_Suicidal_Thoughts__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Suicidal_Thoughts_Description__c: Yup.string(),
      Med_Suicidal_Thoughts_Last__c: Yup.string(),
      Med_Suicide_Attempt__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      //Med_Last_Suicide_Attempt__c: '',
      Med_Alcohol_Drugs__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Alcohol_Drugs_Description__c: Yup.string(),
      Med_Autism_Spectrum__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Autism_Spectrum_Description__c: Yup.string(),
      Med_Missing_Routine_Vaccinations__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Missing_Routine_Vaccinations_Descrip__c: Yup.string(),
      Med_Self_Advocacy_Concerns__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Self_Advocacy_Concerns_Description__c: Yup.string(),
      Med_Suspended_Or_Expelled__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Suspended_Or_Expelled_Description__c: Yup.string(),
      Med_Positive_For_COVID_19__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Covid_19_Fully_Vaccinated__c: Yup.string()
        .oneOf(['0', '1'])
        .required('Please choose one of these options'),
      Med_Covid_19_Vaccine_Type__c: Yup.string(),
      Med_Covid_19_Recent_Booster_Shot__c: Yup.string(),
      Med_Covid_19_Vaccine_Appointment_Date__c: Yup.string(),
      Med_Other__c: Yup.string().oneOf(['0', '1']).required('Please choose one of these options'),
      Med_Other_Description__c: Yup.string(),
    }),
  }),
  //step5
  Yup.object({
    code_of_conduct: Yup.object({
      student_signature_coc: Yup.string().required('Required'),
      parent_signature_coc: Yup.string().required('Required'),
    }),
  }),
]

export interface ICustomForm {
  id: number
  name: string
  client_id: string
}
export interface IBaseField {
  id: number
  name: string
  type: string
  field_type: string
  label: string
  helper_text?: string | null
  choices?: string[] | null
  for?: number | null
  choiceId?: number | null
  group?: string
  required?: boolean
  answer?: string
  choicesObject?: { value: string, title: string }[]
  custom_form?: ICustomForm
  custom_form_id?: number
  enrollment_form_group_id?: number
  status?: string
  group_order?: number
}

export interface IEnrollPartTwoForm extends IBaseField { }

export interface IMedicalField { }

export const MEDICAL_FORM: IMedicalField[] = [
  {
    id: 1,
    name: 'Med_Height__c',
    type: 'question',
    field_type: 'text',
    label: 'Height',
  },
  {
    id: 2,
    name: 'Med_Weight__c',
    type: 'question',
    field_type: 'text',
    label: 'Weight',
  },
  {
    id: 3,
    name: 'Gender',
    type: 'question',
    field_type: 'select',
    label: 'Gender',
    choices: ['---- Select Gender ----', 'Male', 'Female', 'Prefer to self-describe'],
  },
  {
    id: 4,
    name: 'Gender_Description__c',
    type: 'description',
    field_type: 'textarea',
    for: 3,
    choiceId: 3,
    label: 'Describe your gender identity and pronouns',
  },
  {
    id: 5,
    name: 'Sex_Assigned_At_Birth__c',
    type: 'question',
    field_type: 'text',
    label: 'Sex Assigned At Birth',
  },
  {
    id: 6,
    name: 'Pronouns',
    type: 'question',
    label: 'Pronouns',
    field_type: 'select',
    choices: [
      '---- Select Pronoun ----',
      'she, her, hers',
      'he, him, his',
      'they, them, theirs',
      'I do not use he, she, or they, and ask that I`m addressed only by name	',
      'Input Preference',
    ],
  },
  {
    id: 7,
    name: 'pronouns_description',
    type: 'description',
    field_type: 'textarea',
    for: 6,
    choiceId: 5,
    label: 'Please list out your pronouns for staff reference.',
  },
  {
    id: 8,
    name: 'Ethnicity__c',
    type: 'question',
    field_type: 'checkbox',
    label: 'With which race and/or ethnicity do you identify? (Select all that apply)',
    choices: [
      'Asian',
      'Native Hawaiian or Other Pacific Islander',
      'Black or African American',
      'Hispanic or Latino/a',
      'White or Caucasian',
      'American Indian or Alaskan Native',
      'Middle Eastern or North African',
      'An identity not listed, self-identify',
      'Prefer not to say',
    ],
  },
  {
    id: 9,
    name: 'self_identify',
    type: 'description',
    for: 8,
    choiceId: 7,
    field_type: 'textarea',
    label: 'self-identify',
  },
  {
    id: 10,
    name: 'taking_prescription',
    type: 'question',
    field_type: 'boolean',
    label: `Is your student currently taking prescription or physician-recommended medications? Or, do they plan to take prescription or physician-recommended medication during their program?`,
  },
  {
    id: 11,
    name: 'taking_prescription_description',
    type: 'description',
    for: 10,
    choiceId: 1,
    field_type: 'textarea',
    label: `Please list all medications (including dosage, duration of time on medication) and what the medication is for/treating. Include birth control, nicotine patch or gum, antimalarials or anything prescribed during travel. Students are expected to self-administer and keep track of any medications while abroad. Please update us with any changes. You MUST provide information on: 1. Name of medication (brand or generic); 2. Dosage; 3. Amount of time on this medication; 4. What does this medication treat?`,
  },
]

export interface IPredepartureInfo {
  data: IEnrollPartTwoForm[]
  status: {
    emergency_contact_status: string
    preprogram_questionnaire_status: string
  }
  studentInfo?: {
    id: number
    loginguser_id: number
    date_of_birth: Date | string
    shirt_size: string
    citizenship: string
  }
}

export const initsPredepature: any = {
  emergencyContact1: {
    EmergencyContactName__c: '',
    EmergencyContactPrimaryPhoneISO__c: '',
    EmergencyContactPrimaryPhone__c: '',
    EmergencyContactRelation__c: '',
    EmergencyContactSecondaryPhone__c: '',
  },
  emergencyContact2: {
    EmergencyContact2Name__c: '',
    EmergencyContact2PrimaryPhoneISO__c: '',
    EmergencyContact2PrimaryPhone__c: '',
    EmergencyContact2Relation__c: '',
    EmergencyContact2SecondaryPhone__c: '',
  },
  studentInfo: {
    date_of_birth: '',
    shirt_size: '',
    citizenship: '',
  },
}

export const predepatureSchema = Yup.object({
  studentInfo: Yup.object({
    date_of_birth: Yup.date().label('Birthday').required('Birthday is required'),
    shirt_size: Yup.string().required('Required'),
    citizenship: Yup.string().required('Required'),
  }),
  emergencyContact1: Yup.object({
    EmergencyContactName__c: Yup.string().required('Required'),
    EmergencyContactPrimaryPhoneISO__c: Yup.string().required('Required'),
    EmergencyContactPrimaryPhone__c: Yup.string().required('Required'),
    EmergencyContactRelation__c: Yup.string().required('Required'),
    EmergencyContactSecondaryPhone__c: Yup.string().required('Required'),
  }),
  emergencyContact2: Yup.object({
    EmergencyContact2Name__c: Yup.string().required('Required'),
    EmergencyContact2PrimaryPhoneISO__c: Yup.string().required('Required'),
    EmergencyContact2PrimaryPhone__c: Yup.string().required('Required'),
    EmergencyContact2Relation__c: Yup.string().required('Required'),
    EmergencyContact2SecondaryPhone__c: Yup.string().required('Required'),
  }),
})

export interface IQuestionnaire {
  preprogram_questionnaire_1: string
  preprogram_questionnaire_2: string
  other_preprogram_questionnaire_1: string
}

export const initsQuestionnaire: any = {
  questionnaires: {
    // preprogram_questionnaire_1: '',
    // preprogram_questionnaire_2: '',
    // other_preprogram_questionnaire_1: '',
  },
}

const questionnaire = {
  preprogram_questionnaire_1: '',
  preprogram_questionnaire_2: '',
  other_preprogram_questionnaire_1: '',
  Preprogram_Questionnaire_1__c: '',
  Preprogram_Questionnaire_2__c: '',
  Preprogram_Questionnaire_3__c: '',
  Preprogram_Questionnaire_4__c: '',
  Preprogram_Questionnaire_5__c: '',
  Preprogram_Questionnaire_6__c: '',
  Preprogram_Questionnaire_7__c: '',
  Preprogram_Questionnaire_8__c: '',
  Preprogram_Questionnaire_9__c: '',
  Preprogram_Questionnaire_10__c: '',
  Preprogram_Questionnaire_11__c: '',
  Preprogram_Questionnaire_12__c: '',
}

export const questionnaireSchema = Yup.object({
  questionnaires: Yup.object({
    Preprogram_Questionnaire_3__c: Yup.string().required('Required'),
    Preprogram_Questionnaire_4__c: Yup.string().required('Required'),
    Preprogram_Questionnaire_5__c: Yup.string().required('Required'),
    Preprogram_Questionnaire_6__c: Yup.string().required('Required'),
    Preprogram_Questionnaire_7__c: Yup.string().required('Required'),
    Preprogram_Questionnaire_8__c: Yup.string().required('Required'),
    Preprogram_Questionnaire_9__c: Yup.string().required('Required'),
    Preprogram_Questionnaire_10__c: Yup.string().required('Required'),
    Preprogram_Questionnaire_11__c: Yup.string().required('Required'),
    Preprogram_Questionnaire_12__c: Yup.string().required('Required'),
  }),
})
