/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import ResetPassword from './components/ResetPassword'
import {RegistrationSetupStep} from './components/RegistrationSetupStep'
import SwitchProgram from './components/SwitchProgram'
import TerraLogo from '../../../assets/Logo/new-terra-education-logo-color.png'
import useWindowSize from '../../../hooks/useWindowSize'
import './AuthPage.css'

const AuthLayout = () => {
  const {width} = useWindowSize()

  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <>
      <div className={width < 650 ? 'phoneAuth' : 'computerAuth'}>
        <img src={'https://newterraedu.s3.us-west-1.amazonaws.com/static-images/TERRA_LOGO_WITHNAME.png'} alt='Terra Logo' height={60} />
      </div>
      <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
        {/* begin::Content */}
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          {/* begin::Logo */}

          {/* end::Logo */}
          {/* begin::Wrapper */}
          <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
        {/* begin::Footer */}
        <div className='d-flex flex-center flex-column-auto p-10'>
          <div className='d-flex align-items-center fw-bold fs-6'>
            <a
              href='https://www.terraeducation.com/about-us.html'
              className='text-muted text-hover-primary px-2'
            >
              About
            </a>

            <a
              href='https://www.terraeducation.com/contact-us.html'
              className='text-muted text-hover-primary px-2'
            >
              Contact Us
            </a>
          </div>
        </div>
        {/* end::Footer */}
      </div>
    </>
  )
}
const RegistrationLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}

        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='kt_content_container'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a
            href='https://www.terraeducation.com/about-us.html'
            className='text-muted text-hover-primary px-2'
          >
            About
          </a>
          <a
            href='https://www.terraeducation.com/contact-us.html'
            className='text-muted text-hover-primary px-2'
          >
            Contact Us
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password/*'>
        <Route path=':hash/:token' element={<ResetPassword />} />
        <Route path='*' element={<Navigate to='/auth' />} />
      </Route>
      <Route path='program/*'>
        <Route index element={<SwitchProgram />} />
        <Route path='*' element={<Navigate to='/auth' />} />
      </Route>
      <Route index element={<Login />} />
    </Route>
    <Route element={<RegistrationLayout />}>
      <Route path='registration' element={<RegistrationSetupStep />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
