/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import clsx from 'clsx'
import {useNavigate} from 'react-router-dom'
import {useQuery} from 'react-query'
import useAuthRequests from './../auth/hooks/useAuthRequests'
import {TbCircleArrowRightFilled} from 'react-icons/tb'
import TerraLogo from '../../../../src/assets/Logo/new-terra-education-logo-color.png'
import useWindowSize from '../../../hooks/useWindowSize'
import './index.css'

export default function HomePage() {
  const navigate = useNavigate()
  const [redirectClient, setRedirectClient] = useState('88562775')
  const [activeProgram, setActiveProgram] = useState<String | undefined>('')
  //   const changeRedirectOptionHandler = (e: React.ChangeEvent<HTMLSelectElement>): any => {
  //     if (e.target.value != 'Choose a Program') setRedirectClient(e.target.value)
  //   }

  const {width} = useWindowSize()

  const changeRedirectOptionHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== 'Choose a Program') {
      setActiveProgram(e.target.value)
    }
  }

  const handleLoginClick = (e: any) => {
    navigate('/auth')
  }
  const htmlElement: any = document.querySelector('html')
  htmlElement.setAttribute('data-theme', 'light')
  const theme = htmlElement.getAttribute('data-theme')
  const {getPrograms} = useAuthRequests()
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(`getPrograms`, () => {
    return getPrograms()
  })
  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
      </div>
    )
  } else {
    return (
      <div>
        <div className={width < 650 ? 'phone' : 'computer'}>
          <img src={'https://newterraedu.s3.us-west-1.amazonaws.com/static-images/TERRA_LOGO_WITHNAME.png'} alt='Terra Logo' height={60} />
        </div>
        <form className='form w-100' noValidate id='kt_login_signin_form'>
          {/* begin::Heading */}
          <div>
            <div
              className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'
              style={{marginTop: '12%'}}
            >
              <h1 className='text-dark text-center mb-3'>Choose a Program to Enroll In</h1>
              <div className='text-gray-400 fw-bold fs-4'>
                {/* New Here? <br />
                    Choose the program to enroll into: */}
                <div className='program-options'>
                  {response
                    ?.sort((a: any, b: any) => a.id - b.id)
                    .map((c) => {
                      if (c.name !== 'terraEducation') {
                        const isActive = c.reference_id === activeProgram
                        return (
                          <div
                            className={clsx('program-option', {active: isActive})}
                            key={c.id}
                            onClick={() => setActiveProgram(c.reference_id)}
                          >
                            {c.name}
                            {/* {isActive && <TbCircleArrowRightFilled size={30} />} */}
                          </div>
                        )
                      }
                      return null
                    })}
                </div>

                {/* <Link to={`/enroll/${redirectClient}`} className='link-primary fw-bolder'
                            style={{marginTop:'20px'}}
                            >
                                Click here to start Enrollment.
                            </Link> */}
                <div style={{display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
                  <button
                    className='btn btn-primary fw-bolder'
                    style={{width: '70%', borderRadius: '50px'}}
                    onClick={(e) => {
                      navigate(`/enroll/${activeProgram}`)
                    }}
                  >
                    Click here to start enrollment
                  </button>
                </div>
              </div>
              {theme == 'dark' ? (
                <>
                  <div className='homepage-line' style={{marginTop: '40px'}}>
                    {/* <h2 style={{ width: '100%', textAlign: 'center', borderBottom: '1px solid white', lineHeight: '0.1em', margin: '10px 0 20px' }}><span style={{ padding: '0 10px', background: '#1e1e2e' }}>OR</span></h2> */}
                  </div>
                  <div style={{display: 'flex', justifyContent: 'center', marginTop: '40px'}}>
                    <h4>
                      Already a User?{' '}
                      <span
                        style={{
                          textDecorationLine: 'underline',
                          color: '#019ef7',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          handleLoginClick(e)
                        }}
                      >
                        Login
                      </span>
                    </h4>
                    {/* <button className='btn btn-primary' onClick={(e) => { handleLoginClick(e) }}>Login</button> */}
                  </div>
                </>
              ) : (
                <>
                  <div className='homepage-line' style={{marginTop: '40px'}}>
                    {/* <h2 style={{ width: '100%', textAlign: 'center', borderBottom: '1px solid black', lineHeight: '0.1em', margin: '10px 0 20px' }}><span style={{ padding: '0 10px', background: 'white' }}>OR</span></h2> */}
                  </div>
                  <div style={{display: 'flex', justifyContent: 'center', marginTop: '40px'}}>
                    <h4>
                      Already a User?{' '}
                      <span
                        style={{
                          textDecorationLine: 'underline',
                          color: '#019ef7',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          handleLoginClick(e)
                        }}
                      >
                        Login
                      </span>
                    </h4>
                    {/* <button className='btn btn-primary' onClick={(e) => { handleLoginClick(e) }}>Login</button> */}
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
        <div
          className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
          //   style={{
          //     backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
          //   }}
        >
          {/* begin::Content */}
          <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
            {/* begin::Logo */}

            {/* end::Logo */}
            {/* begin::Wrapper */}
            <div className='kt_content_container'>{/* <Outlet /> */}</div>
            {/* end::Wrapper */}
          </div>
          {/* end::Content */}
          {/* begin::Footer */}
          <div className='d-flex flex-center flex-column-auto p-10'>
            <div className='d-flex align-items-center fw-bold fs-6'>
              <a
                href='https://www.terraeducation.com/about-us.html'
                className='text-muted text-hover-primary px-2'
              >
                About
              </a>
              <a
                href='https://www.terraeducation.com/contact-us.html'
                className='text-muted text-hover-primary px-2'
              >
                Contact Us
              </a>
            </div>
          </div>
          {/* end::Footer */}
        </div>
      </div>
    )
  }
}
