import { Form, Formik } from 'formik'
import React from 'react'
import CustomInput from './CustomInput'
import CustomSelect from './CustomSelect'
import CustomCheckbox from './CustomCheckbox'

export interface IInitialValue {
  name?: string
  type?: string
  due?: number
  amount?: number
  qb_name?: string
  coa?: string
  division?: string
  is_active?: boolean
  scholarship_code?: string
  sf_id?: string
  pb_id?: string
}

interface IProps {
  initialValues: IInitialValue
  onSubmit: (value: IInitialValue) => void
  loading?: boolean
}

const CustomForm = ({ initialValues, onSubmit, loading }: IProps) => {
  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-body'>
        <div className='card'>
          <Formik
            initialValues={initialValues}
            // validationSchema={EnrollmentFormSchema}
            onSubmit={onSubmit}
          >
            {({values, setFieldValue, isSubmitting}) => {

              return (
                <Form>
                  <CustomInput
                    required={true} 
                    label='Name' 
                    name='name' 
                    placeholder='Enter name...'
                  />

                  <CustomSelect
                    required={true} 
                    label='Division' 
                    name='division' 
                    options={
                      [{
                        label: 'SSB',
                        value: 'SSB',
                      }, {
                        label: 'TTN',
                        value: 'TTN',
                      }]
                    }
                    placeholder='Select division...'
                  />
                  
                  <CustomSelect
                    required={true} 
                    label='Type' 
                    name='type' 
                    options={
                      [{
                        label: 'Scholarship',
                        value: 'Scholarship',
                      }, {
                        label: 'Discount',
                        value: 'Discount',
                      }]
                    }
                    placeholder='Select type...'
                  />
                  
                  <CustomSelect
                    label='COA' 
                    name='coa' 
                    options={
                      [{
                        label: 'Program Fees at FULL Rate',
                        value: 'Program Fees at FULL Rate',
                      }, {
                        label: 'Less Cash Discounts',
                        value: 'Less Cash Discounts',
                      }]
                    }
                    placeholder='Select COA...'
                  />

                  <CustomInput
                    label='Due Upon Application' 
                    name='due' 
                    type='number'
                    placeholder='Enter due...'
                  />

                  <CustomInput
                    required={true} 
                    label='Amount' 
                    name='amount' 
                    type='number'
                    placeholder='Enter amount...'
                  />
                  
                  <CustomInput
                    label='QB Full Name' 
                    name='qb_name' 
                    placeholder='Enter name...'
                  />

                  <CustomInput
                    label='Scholarship Code' 
                    name='scholarship_code' 
                    placeholder='Enter code...'
                  />
                  
                  <CustomCheckbox
                    label='Status'
                    name='status'
                    setFieldValue={setFieldValue}
                    values={values.is_active}
                  />
                  <div className='text-center pt-15'>
                    <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                      Submit
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default CustomForm