import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { axiosPrivate } from '../../../../../axiosInstance'

const DeleteAddonCharges = ({item, refetch}: any) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  console.log(item, 'itemitem')
  const onSubmit = async () => {
    try {
      setLoading(true)
      const DeleteValues = {
        opportunity_id: item.opportunity_id,
        sf_id: item.sf_id,
        opportunity_detail_id: item.id,
        price: item.price,
      }
      const response = await axiosPrivate.post('/delete_addon', DeleteValues)
      if (response.status === 201) {
        setSuccessMessage(response.data.message)
      }
      setTimeout(() => {
        handleClose()
      }, 3000)
    } catch (error: any) {
      setLoading(false)
      setErrorMessage(error.response.data.message)
      setTimeout(() => {
        setErrorMessage('')
      }, 3000)
    } finally {
      setLoading(false)
      refetch()
    }
  }

  const handleClose = () => {
    setShowModal(prev => !prev)
    setLoading(false)
    setSuccessMessage('')
    setErrorMessage('')
  }

  return (
    <>
      <span className='text-danger cursor-pointer text-hover' onClick={handleClose}>Delete</span>
      <Modal show={showModal} onHide={handleClose} backdrop='static' keyboard={false} centered>
        <Modal.Body className='p-0'>
          <Modal.Header closeButton>
              <Modal.Title>Are you sure you want to remove this Addon Charge?</Modal.Title>
          </Modal.Header>

          <p style={{margin: '20px 50px 20px 23px', fontSize: '16px'}}>This will remove this Addon. You cannot undo this action.</p>

          {errorMessage ? (
            <div className='card-body'>
              <div className='alert alert-danger d-flex align-items-center px-5 py-3 mx-7'>
                <div className='d-flex flex-column '>
                  <span>{errorMessage}</span>
                </div>
              </div>
            </div>
          ) : ''}

          {successMessage ? (
            <div className='card-body'>
              <div className='alert alert-success d-flex align-items-center px-5 py-3 mx-7'>
                <div className='d-flex flex-column '>
                  <span>{successMessage}</span>
                </div>
              </div>
            </div>
          ) : ''}

          </Modal.Body>
          <Modal.Footer style={{padding: '20px 30px'}}>
            <Button variant='secondary' onClick={handleClose}>
              Discard
            </Button>
            <Button 
              variant='danger' 
              className='ms-2' 
              onClick={onSubmit}
            >
              {loading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : 'Remove'}
            </Button>
          </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeleteAddonCharges