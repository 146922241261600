import { useEffect, useState } from 'react'
import { PREV_AUTH_LOCAL_STORAGE_KEY, getAuthPromise } from '../../../../modules/auth'
import { axiosPrivate } from '../../../../../axiosInstance'
import { useParams } from 'react-router-dom'
import useAppRequests from "../../../../modules/application/Hooks/useAppRequests";
import { IPredepartureItem } from "../../../../modules/application/core/_models"
import { ToastContainer, toast } from 'react-toastify';
import ReactHTMLParser from 'react-html-parser';
import moment from "moment";
import './index.css'
import WaiverModalConfirm from './WaiverModalConfirm'

interface Props {
  predeparture?: IPredepartureItem
}

const Waiver = ({predeparture}: Props) => {
  const [waiverDocumentData, setWaiverDocumentData] = useState<any>([])
  const { opportunity } = useParams()
  
  const [disable, setDisable] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  const [signatureInfo, setSignatureInfo] = useState({
    parent: { signedOn: 0, status: 'not yet signed', signStatus: false, signatureID: '' },
    student: { signedOn: 0, status: 'not yet signed', signStatus: false, signatureID: '' }
  });
  
  const { fetchHelloSignRequestByIDReq, updatePredepartureTaggingReq } = useAppRequests();
  const fetchHelloSignRequestByID = async () => {
    const helloSignRequest = await fetchHelloSignRequestByIDReq(opportunity, predeparture?.id)

    if (helloSignRequest) {
      const signatures = helloSignRequest.signatures.reduce((acc: any, signature: any) => {
        acc[signature.signer_name] = {
          signedOn: signature?.signed_at,
          status: signature?.status_code,
          signStatus: helloSignRequest[`${signature?.signer_name}_sign_status`],
          signatureID: signature?.signature_id
        };
        return acc;
      }, {});
      setSignatureInfo(signatures);
    }
  }
  const DateFormatterUnix = (unixcode: number) => {
    if (unixcode === 0 || unixcode === null) {
      return 'N / A'
    }
    return moment.unix(unixcode).format('LLL')
  }
  
  const getHelloSignDocuments = async () => {
    try {
      const response = await axiosPrivate.get(`/hellosign/documents/${opportunity}/${predeparture?.id}`)
      setWaiverDocumentData(response.data.document_list);
    } catch (error) {
      console.error(error)
    }
  }

  const isUserAnAdmin = async () => {
    const prevAuth = await getAuthPromise(PREV_AUTH_LOCAL_STORAGE_KEY)

    if (prevAuth) {
      setIsAdmin(true)
    }
  }

  useEffect(() => {
    if (predeparture) {
      isUserAnAdmin()
      fetchHelloSignRequestByID();
    }
  }, [predeparture]
  )

  useEffect(() => {
    if (predeparture) {
      getHelloSignDocuments();
    }
  }, [predeparture, isAdmin])

  const changeDocumentStatus = (event: any) => {
    setSelectedStatus(event.target.value);
  }

  const saveDocumentStatus = async (event: any) => {
    event.preventDefault();

    const formData = {
      opportunity_id: Number(opportunity),
      predeparture_id: predeparture?.id,
      predeparture_task_name: predeparture?.name,
      status_name: selectedStatus
    }

    const response = await updatePredepartureTaggingReq(formData);

    if (response) {
      setStatusMessage(response.message);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setSelectedStatus("");
      setStatusMessage("");
    }, 5000);
  }, [statusMessage])

  const handleRequestSignature = async (programs: any) => {
    setDisable(true)
    await handleCallRequest(programs.program_id, programs.id, programs.program_document_id)
  }

  const handleCallRequest = async (programID: number, documentID: number, programDocumentID: number) =>{
    const dataToSend = {
      program_id: programID,
      document_id: documentID,
      program_document_id: programDocumentID,
      opportunity_id: Number(opportunity),
    }

    try {
      await axiosPrivate.post('/hellosign/request', dataToSend)
    } catch (error: any) {
      setDisable(false)
      alert(error.response.data.message)
    }
  }

  const capitalFirstLetter = (string: string) => {
    return string.toLowerCase().replace(
      /(^|\s)\S/g,
      (match) => match.toUpperCase())
  }

  const NotificationMessage = async (values: string) => {
    toast.warn(values);
  };

  const statusClassName = signatureInfo.student.status === "signed" ? "text-success" : signatureInfo.student.status === "awaiting_signature"
      ? "text-primary"
      : "text-dark"

  const statusClassNameP = signatureInfo.parent.status === "signed" ? "text-success" : signatureInfo.parent.status === "awaiting_signature"
  ? "text-primary"
  : "text-dark"

  return (
    <div className='card mb-5 mb-xl-8'>
      <ToastContainer style={{ width: "50vw" }}
        position="top-center"
        autoClose={false}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className='card-body'>
        <div className='card-body py-3'>
        <div className='d-flex justify-content-center align-items-center card-header bg-primary rounded w-75 mx-auto mb-5'>
          <h1 className='fw-bolder fs-2x text-light text-uppercase'>
            {waiverDocumentData?.template_title}
          </h1>
        </div>
        {predeparture && (
        <div className='instructions mb-5'>
          <h2>Instructions</h2>
          {ReactHTMLParser(predeparture?.instruction) || ''}
        </div> 
        )}
          <div className='table-responsive'>
            <table className='table table-row-dashed align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='bg-light text-center text-muted fw-bold'>
                  <th className='ps-4 rounded-start'>Role</th>
                  <th>Signature Status</th>
                  <th>Signed by</th>
                  <th>Action</th>
                  {/* {isAdmin &&  <th className='rounded-end'>Admin Action</th>} */}
                </tr>
              </thead>
              {/* end::Table head */}
              <tbody className='fs-5'>
                <tr className="text-center">
                  <td>
                    <span className='text-dark d-block'>Student</span>
                  </td>
                  <td className={statusClassName}>
                    <span>{capitalFirstLetter(signatureInfo.student.status === 'awaiting_signature' ? 'awaiting signature' : signatureInfo.student.status)}</span>
                  </td>
                  <td>
                    <span className="text-muted">
                      {DateFormatterUnix(signatureInfo.student.signedOn)}
                    </span>
                  </td>
                  <td className={statusClassName}>
                  {signatureInfo.student.status === "signed" ?
                    <div className='text-success'>
                      Completed
                    </div>
                    : signatureInfo.student.status === 'awaiting_signature' ? <p className='text-gray'>Awaiting Signature</p> : <p className='text-gray'>Request Signature</p>}
                  </td>
                  {/* {isAdmin &&  
                  <td>
                   <WaiverModalConfirm
                      opportunityID={opportunity}
                      questionMessage="Are you sure you want to revert this student signature status?"
                      title="Reset Confirmation"
                      color="warning"
                      actionType='revert'
                      data={{student_sign_status: false}}
                    />
                  </td>} */}
                </tr>
                <tr className="text-center">
                  <td>
                    <span className='text-dark d-block'>Parent</span>
                  </td>
                  <td className={statusClassNameP}>
                    <span>{capitalFirstLetter(signatureInfo.parent.status === 'awaiting_signature' ? 'awaiting signature' : signatureInfo.parent.status)}</span>
                  </td>
                  <td>
                    <span className="text-muted">
                      {DateFormatterUnix(signatureInfo.parent.signedOn)}
                    </span>
                  </td>
                  <td className={statusClassNameP}>
                    {signatureInfo.parent.status === "signed" ? 
                    <div className='text-success'>
                      Completed
                    </div>
                    : signatureInfo.student.status === 'awaiting_signature' ? <p className='text-gray'>Awaiting Signature</p> : <p className='text-gray'>Request Signature</p>}
                  </td>
                  {/* {isAdmin &&  
                  <td>
                   <WaiverModalConfirm 
                      opportunityID={opportunity}
                      questionMessage="Are you sure you want to revert this parent signature status?"
                      title="Reset Confirmation"
                      color="warning"
                      actionType='revert'
                      data={{parent_sign_status: false}}
                    />
                  </td>
                  } */}
                </tr>
              </tbody>
            </table>
            <span className="text-dark d-block">  
            {signatureInfo.student.status === "awaiting_signature" || signatureInfo.student.status === "signed" ? (
              <button disabled className="btn btn-primary">Requested Signature</button>
            ) : (
              <button
                className="btn btn-primary"
                disabled={disable}
                onClick={() => {
                  handleRequestSignature(waiverDocumentData);
                  NotificationMessage(`
                    The waiver document links have been sent to your respective email addresses (Parent and Student). 
                    Please check your emails and follow the steps outlined in the document. 
                    Once you have completed the process and signed the waivers, your signature status will be updated.
                  `);
                }}
              >
                Request Signature
              </button>
            )}
            </span>
            {isAdmin &&
              <section className="mt-12">
                <hr />

                <form
                  className="d-flex flex-column my-3"
                  onSubmit={(event: any) => saveDocumentStatus(event)}>
                  <h3 className="ps-4">Change Document Status:</h3>

                  <select
                    className="form-select border border-primary w-50 mt-3 mb-5 py-2"
                    value={selectedStatus}
                    onChange={changeDocumentStatus}>
                    <option value="">Choose New Status</option>
                    <option value="Incomplete">Incomplete</option>
                    <option value="Confirmed">Confirmed</option>
                  </select>

                  <button type="submit" className="btn btn-primary w-50">Save</button>
                </form>

                {statusMessage &&
                  <span
                    className={statusMessage === "Predeparture Status successfully updated!"
                      ? "w-50 text-center text-success"
                      : "w-50 text-center text-danger"}>
                    {statusMessage}
                  </span>}
              </section>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Waiver