/* eslint-disable react-hooks/exhaustive-deps */
import { initialQueryState } from '../../../../../../_metronic/helpers';
import {ReusableSearchComponent} from '../../../../Shared/components/ReusableSearchComponent';
import { useQueryRequest } from '../../../../Shared/core/QueryRequestProvider';

const ProductListSearchComponent = () => {
  const { updateState } = useQueryRequest();
  const handleSearch = (searchTerm: string) => {
    updateState({ search: searchTerm, ...initialQueryState });
  };
  
  return (
    <div className='card-title'>
      {/* begin::Search */}
        <ReusableSearchComponent placeholder="Search Product" onSearch={handleSearch} />
      {/* end::Search */}
    </div>
  )
}

export {ProductListSearchComponent}
