import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import {useAuth} from '../auth'
import ConfirmPayBanner from './components/ConfirmPay/ConfirmPayBanner'
import ConfirmPayTable from './components/ConfirmPay/ConfirmPayTable'
import PayOpt from './components/ConfirmPay/PayOpt'
import GLALayout from './components/GLALayout'
import _queryKeys from './core/_queryKeys'
import useAppRequests from './Hooks/useAppRequests'
import PaymentProtectionPlan from './PaymentProtectionPlan'

const ConfirmAndPay = () => {
  const {currentUser} = useAuth()
  const {getPaymentSummaryReq} = useAppRequests()
  const {client, opportunity} = useParams()
  const [isChecked] = useState(false);
  const protection_price = 0;
  const inputs = {
    protection_price: protection_price,
    isChecked: isChecked,
  }

  const {
    isLoading,
    isError,
    data: paymentSummary,
    refetch,
  } = useQuery(_queryKeys.getRecentPaymentSummariesReq, () =>
  getPaymentSummaryReq(client || '', Number(opportunity) || -1)
  )
  
  useEffect(() => {
    refetch()
    return () => {}
  }, [currentUser])

  // if (paymentSummary?.opp_status.name != 'Incomplete_Deposit_Due') {
  //   return <Navigate to={`/dashboard/${client}`} />
  // }
  console.log(paymentSummary)
  const paymentContainer = !isError ? (
    <>
      <ConfirmPayTable inputObject={inputs} isLimit={paymentSummary?.opp_status.id == 18}/>
      <h4>
        <strong>
          Please choose a payment method below. You can choose ACH/e-Check through your bank or pay
          by credit card. If you are sponsored by an outside organization, you can also enter those
          details here.
        </strong>
      </h4>
      <PayOpt isChecked={isChecked} inputObject={inputs}  isLimit={paymentSummary?.opp_status.id == 18}/>
    </>
  ) : (
    <div className='alert alert-danger d-flex align-items-center p-5 my-10'>
      <div className='d-flex flex-column'>
        <h5 className='mb-1'>Oops!</h5>
        <span>Something went wrong!</span>
      </div>
    </div>
  )

  return (
    <GLALayout>
      <>
        {client === '47821104' && paymentSummary?.opp_status.id != 18 ? "" : ""}
        
        <ConfirmPayBanner title='Reserve Your Spot' subheader='' supportingHeader='' />
        <div className='container'>
          {isLoading ? (
            <div className='w-100 my-10'>
              <div className='d-flex justify-content-center align-items-center'>
                <span className='me-5'>Please wait...</span>
                <div className='spinner-border text-primary'>
                  <output className='visually-hidden'>Loading...</output>
                </div>
              </div>
            </div>
          ) : (
            paymentContainer
          )}
        </div>
      </>
    </GLALayout>
  )
}

export default ConfirmAndPay
