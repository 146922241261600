import {useMutation, useQuery} from 'react-query'
import _queryKeys from '../../../modules/application/core/_queryKeys'
import useAppRequests from '../../../modules/application/Hooks/useAppRequests'
import {Formik, Form, FormikValues, Field, ErrorMessage} from 'formik'
import {initsPredepature, IPredepartureItem, predepatureSchema, T_SHIRT_SIZE} from '../../../modules/application/core/_models'
import {COUNTRIES} from '../../../../utils'
import DatePickerField from '../../../modules/Shared/components/DatePickerField'
import FilterField from '../../../modules/application/components/Shared/FilterField'
import ReactHtmlParser from 'react-html-parser'
import {useAuth} from '../../../modules/auth'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import { useParams } from 'react-router-dom'
interface Props {
  predeparture: IPredepartureItem,
  opportunity: string,
}
const PredepartureInfo = ({predeparture, opportunity}:Props) => {
  const {currentUser} = useAuth()
  const {fetchPredetureInfoFormsReq, saveAndContinueLater} = useAppRequests()
  const { client} = useParams()
  const clientRoutes = client === '47821104' ? 'SSB' : client === '88562775' ? 'TTN' : 'GLA'
  const {
    isLoading: isFieldLoading,
    isError: isFieldError,
    data: fieldFormsData,
    refetch: refetchfieldFormsData,
  } = useQuery(_queryKeys.fetchPredetureInfoFormsReq, () => fetchPredetureInfoFormsReq('emergency', 'pre-departure-info', Number(opportunity)))

  //console.log('PredepartureInfo', fieldFormsData?.data)

  const emergencyContact1 = fieldFormsData?.data.filter((f) => f.group === 'emergency_contact1')
  const emergencyContact2 = fieldFormsData?.data.filter((f) => f.group === 'emergency_contact2')

  emergencyContact1?.forEach((val) => {
    initsPredepature.emergencyContact1[val.name] = val.answer
      ? val.field_type === 'checkbox'
        ? val.answer.split(',')
        : val.answer
      : ''
  })
  emergencyContact2?.forEach((val) => {
    initsPredepature.emergencyContact2[val.name] = val.answer
      ? val.field_type === 'checkbox'
        ? val.answer.split(',')
        : val.answer
      : ''
  })
  if (fieldFormsData?.studentInfo) {
    initsPredepature.studentInfo = fieldFormsData?.studentInfo

    initsPredepature.studentInfo.date_of_birth = fieldFormsData?.studentInfo?.date_of_birth
      ? moment(fieldFormsData?.studentInfo?.date_of_birth).format('MM/DD/YYYY')
      : initsPredepature.studentInfo.date_of_birt
  }

  const {
    isLoading: isSaveLoading,
    isSuccess: isSaveSuccess,
    isError: isSaveResponseError,
    mutate: saveResponse,
  } = useMutation(saveAndContinueLater)
  //console.log('4 ', emergencyContact1)

  const onSave = (values: any, actions: FormikValues) => {
    console.log('onsave', values)
    values.emergencyContact1.predeparture_id = predeparture.id
    const inputs: any = {
      opportunity: currentUser?.currentOpportunity,
      emergency: {...values},
      isComplete: true,
    }
    saveResponse(inputs, {
      onSuccess: (res) => {
        if (res.data) {
          refetchfieldFormsData()
        }
      },
      onError: (err) => {
        console.log(err)
      },
    })
  }
  //console.log('5 ', emergencyContact1)

  if (isFieldError) {
    return (
      <div className='alert alert-danger d-flex align-items-center p-5 my-10'>
        <div className='d-flex flex-column'>
          <h5 className='mb-1'>Oops!</h5>
          <span>Something went wrong!</span>
        </div>
      </div>
    )
  }
  //console.log('6 ', emergencyContact1)

  return (
    <>
      {isSaveSuccess && (
        <div className='alert alert-success d-flex align-items-center p-5 mb-10'>
          <div className='d-flex flex-column '>
            <span>Your information has been saved and submitted to {clientRoutes} for review.</span>
          </div>
        </div>
      )}
      {isSaveResponseError && (
        <div className='alert alert-danger d-flex align-items-center p-5 my-10'>
          <div className='d-flex flex-column'>
            <h5 className='mb-1'>Oops!</h5>
            <span>Could not process your forms!</span>
          </div>
        </div>
      )}
      {isSaveLoading && (
        <div id='splash-screen2' className='splash-screen2'>
          <div className='border rounded bg-white py-5 px-15'>
            <span className='fs-3 fw-bold'>Saving ...</span>
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </div>
        </div>
      )}

      <div className='card mb-5 mb-xl-8'>
        <div className='card-body'>
          <div className='card-header px-0'>
            <h1>Student & Emergency Contact Info</h1>
          </div>
          <Formik
            validationSchema={predepatureSchema}
            initialValues={initsPredepature}
            onSubmit={onSave}
          >
            {({values, errors, setFieldValue}) => {
              return (
                <Form>
                  <div className='mb-10'>
                    <h3 className='fw-normal text-dark my-5'>Student's Information</h3>
                    <div className='row mb-7'>
                      <label className='form-label fw-bolder text-dark fs-6 required'>
                        Citizenship
                      </label>
                      <Field
                        as='select'
                        name='studentInfo.citizenship'
                        className='form-select form-select-lg form-select-solid'
                      >
                        <option>Select Country</option>
                        {COUNTRIES.map((c, idx) => {
                          return (
                            <option value={c.code} key={idx}>
                              {c.name}
                            </option>
                          )
                        })}
                      </Field>
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='studentInfo.citizenship' />
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='form-label fw-bolder text-dark fs-6'>T-Shirt Size</label>
                      <Field
                        as='select'
                        name='studentInfo.shirt_size'
                        className='form-select form-select-lg form-select-solid'
                      >
                        <option>---- Select Size ---- </option>
                        {T_SHIRT_SIZE.map((size, idx) => {
                          return (
                            <option value={size.value} key={idx}>
                              {size.adult}
                            </option>
                          )
                        })}
                      </Field>
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='studentInfo.shirt_size' />
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <label className='form-label fw-bolder text-dark fs-6 required'>
                        Date of Birth (MM/DD/YYYY)
                      </label>
                      <DatePickerField
                        className='form-control form-control-lg form-control-solid'
                        name='studentInfo.date_of_birth'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='studentInfo.date_of_birth' />
                      </div>
                    </div>
                  </div>
                  <div className='border-top'>
                    <div className='my-5'>
                      <h3 className='fw-normal text-dark'>Emergency Contact Information</h3>
                      <span>
                        Please list contact information for TWO family members. At least one contact
                        must be a parent or guardian. Please make sure contact information is
                        accurate for the dates the student will be with GLA.
                      </span>
                    </div>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <h3 className='my-5'>Contact Person 1</h3>
                        {emergencyContact1?.map(
                          (form, idx) =>
                            form.type === 'question' && (
                              <div className='col-lg-12 mb-5' key={idx}>
                                <label className={'form-label fw-bolder text-dark fs-6 required'}>
                                  {ReactHtmlParser(form.label)}
                                </label>
                                <div className='fw-bold text-dark mb-2'>
                                  <span>{form.helper_text}</span>
                                </div>
                                <FilterField form={form} prefixFieldName='emergencyContact1.' />
                                <div className='text-danger mt-2'>
                                  <ErrorMessage name={'emergencyContact1.' + form.name} />
                                </div>
                              </div>
                            )
                        )}
                      </div>
                      <div className='col-lg-6'>
                        <h3 className='my-5'>Contact Person 2</h3>
                        {emergencyContact2?.map(
                          (form, idx) =>
                            form.type === 'question' && (
                              <div className='col-lg-12 mb-5' key={idx}>
                                <label className={'form-label fw-bolder text-dark fs-6 required'}>
                                  {ReactHtmlParser(form.label)}
                                </label>
                                <div className='fw-bold text-dark mb-2'>
                                  <span>{form.helper_text}</span>
                                </div>
                                <FilterField form={form} prefixFieldName='emergencyContact2.' />
                                <div className='text-danger mt-2'>
                                  <ErrorMessage name={'emergencyContact2.' + form.name} />
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                    <div className='text-end'>
                      <button type='submit' className='btn btn-lg btn-primary me-3'>
                        {!isSaveLoading && (
                          <span className='indicator-label'>
                            Save{' '}
                            <i className='fa-regular fa-floppy-disk ms-2'></i>
                          </span>
                        )}
                        {isSaveLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default PredepartureInfo
