import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { axiosPrivate } from '../../../../../axiosInstance'

interface IProps {
  item: any
  refetch: any
}

const EditScholarship = ({ item, refetch }: IProps) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [price, setPrice] = useState('0')
  const [due, setDue] = useState('0')
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  const handleClose = () => {
    setShowModal(prev => !prev)
    setLoading(false)
    setErrorMessage('')
    setSuccessMessage('')
    setPrice(item.price || '0')
    setDue(item.due_upon_application || '0')
  }

  const onSubmit = async () => {
    try {
      setLoading(true)
      setErrorMessage('')
      setSuccessMessage('')
      await axiosPrivate.post('/user/update_discount', {
        id: item.id,
        sf_id: item.sf_id,
        oppId: item.opportunity_id,
        price: Number(price),
        old_price: item.price || '',
        due: Number(due),
        old_due: item.due_upon_application || 0
      })
      setSuccessMessage('Successfully updated. Please wait for a moment to allow the changes to be reflected')
      refetch()
      setTimeout(() => {
        handleClose()
      }, 1000)
    } catch (error: any) {
      setErrorMessage(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <span className='text-primary ms-10' style={{cursor: 'pointer'}} onClick={handleClose}>Edit</span>
      <Modal show={showModal} onHide={handleClose} backdrop='static' keyboard={false} centered>
        <Modal.Body className='p-0'>
          <Modal.Header closeButton>
              <Modal.Title>Edit Scholarship</Modal.Title>
          </Modal.Header>

          <div className='my-7 px-7'>
            <p className='fw-bold'>{item.product_name}</p>
          </div>

          <div className='fv-row mb-7 px-7'>
            <div className='flex-grow-1 required fw-bold mb-2'>Total price</div>
            <input 
              type="number" 
              className='form-control form-control-solid mb-3 mb-lg-0' 
              value={price} 
              onChange={(e) => setPrice(e.target.value)} />
          </div>

          <div className='fv-row mb-7 px-7'>
            <div className='flex-grow-1 required fw-bold mb-2'>Due upon application</div>
            <input 
              type="number" 
              className='form-control form-control-solid mb-3 mb-lg-0' 
              value={due} 
              onChange={(e) => setDue(e.target.value)} />
          </div>

          {errorMessage ? (
            <div className='card-body'>
              <div className='alert alert-danger d-flex align-items-center px-5 py-3 mx-7'>
                <div className='d-flex flex-column '>
                  <span>{errorMessage}</span>
                </div>
              </div>
            </div>
          ) : ''}

          {successMessage ? (
            <div className='card-body'>
              <div className='alert alert-success d-flex align-items-center px-5 py-3 mx-7'>
                <div className='d-flex flex-column '>
                  <span>{successMessage}</span>
                </div>
              </div>
            </div>
          ) : ''}

        </Modal.Body>

        <Modal.Footer style={{padding: '20px 30px'}}>
          <Button variant='secondary' onClick={handleClose}>
            Discard
          </Button>
          <Button 
            variant='primary' 
            className='ms-2' 
            onClick={onSubmit}
          >
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : 'Update'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditScholarship